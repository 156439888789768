<template>
  <NuxtLayout>
    <div class="loading" v-if="loading">
      <div
        class="loading__lane"
        :class="{loading__lane_lighter: isLoadingLighter}"
      ></div>
    </div>
    <!-- pwa mode -->
    <!-- <VitePwaManifest /> -->
    <!-- pwa mode start -->
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
import {useRoute} from 'vue-router';
import {useI18n} from 'vue-i18n';
import {getLocaleFromPath} from '~/helpers/getLocaleFromPath';
import {useCookie, useRouter} from '#imports';
import {getLocaleList} from '~/helpers/getLocaleList';
import Logger from '~/helpers/logger';
import {useCookieStore} from '~/modules/Cookies/cookie.store';
import {useBaseStore} from './modules/BaseModule/base.store';
import {useDirectionStore} from '~/store/direction.store';

const loading = ref(true);
const {locale} = useI18n();
const route = useRoute();
const router = useRouter();
const locales = getLocaleList();

const cookie = useCookieStore();
const base = useBaseStore();
const direction = useDirectionStore();

const isLoadingLighter = computed(() => {
  return route.fullPath.includes('/offer');
});

const localeFromPath = getLocaleFromPath(route.fullPath);
const langFromCookie = useCookie('lang');

if (localeFromPath) {
  locale.value = localeFromPath;
  langFromCookie.value = localeFromPath;
} else if (!localeFromPath && langFromCookie.value) {
  locale.value = langFromCookie.value;
}

direction.$subscribe((_, state) => {
  if (state.isRight) {
    document.body.setAttribute('dir', 'rtl');
  } else {
    document.body.setAttribute('dir', 'ltr');
  }
});

watch(
  () => locale.value,
  (newLocale) => {
    if (newLocale === 'ar') {
      direction.setDirection(true);
      document.body.removeAttribute('ar');
    } else {
      direction.setDirection(false);
      document.body.setAttribute('ar', '');
    }
    let clearRoute = route.fullPath;
    langFromCookie.value = newLocale;

    locales.forEach((localeName) => {
      clearRoute = clearRoute.replace(
        new RegExp(`^\\/${localeName}`),
        '',
      );
    });
    document.documentElement.setAttribute('lang', newLocale);
    router.push(`/${newLocale}` + clearRoute);
  },
);

let logger: Logger;

function keyPressHandler(event: KeyboardEvent) {
  if (event.ctrlKey && event.altKey && event.code === 'KeyD') {
    logger.download();
  }
}

declare global {
  interface Window {
    ChatraID: string;
    ChatraSetup: object;
  }
}
// Info about chatra api - https://chatra.com.br/help/api/

function addChatra(c: string) {
  if (process.env.NODE_ENV === 'production') {
    window.ChatraID = 'HssEWWWicJoeTnMnJ';
    window.ChatraSetup = {
      disableChatOpenHash: true,
    };
    const script = document.createElement('script');
    window[c] =
      window[c] ||
      function () {
        (window[c].q = window[c].q || []).push(arguments);
      };
    script.async = true;
    script.src = 'https://call.chatra.io/chatra.js';
    if (
      document.head &&
      localStorage.getItem('accept_cookie') === 'accept'
    ) {
      document.head.appendChild(script);
    }
  }
}

cookie.$subscribe((_, state) => {
  if (state.accept_all_cookie) {
    addChatra('Chatra');
  }
});

onMounted(() => {
  if (localeFromPath === 'ar') {
    direction.setDirection(true);
  }
  logger = Logger.getInstance();
  window.addEventListener('keydown', keyPressHandler);

  addChatra('Chatra');
  loading.value = false;
  base.initializationApp();

  if (locale.value === 'ar') {
    document.body.setAttribute('ar', '');
  }
  document.documentElement.setAttribute('lang', locale.value);
});

onUnmounted(() => {
  window.removeEventListener('keydown', keyPressHandler);
});
</script>

<style lang="scss">
.loading {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 3px;
  z-index: 9999999;

  .loading__lane {
    position: absolute;
    width: 0%;
    height: 4px;
    background: #2491e7;
    border-radius: 16px;
    animation: loading 2s ease-in-out infinite;

    &_lighter {
      background: #5bb7fd;
    }
  }
}

@keyframes loading {
  0% {
    width: 3%;
    left: 0px;
  }
  50% {
    width: 6%;
  }
  100% {
    width: 2%;
    left: 100vw;
  }
}
.page-enter-active,
.page-leave-active {
  transition: all 0.3s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(2px);
}

.q-carousel__slide {
  border-radius: 8px;
}

a {
  text-decoration: none;
  color: inherit;
}
</style>
