<template>
  <button
    class="button h5"
    :class="{
      [type]: !!type,
      [`button-${color}`]: !!color,
      'button--disabled': disabled,
      'button--disabled_control': disabledControl,
      [`button--outlined`]: outline,
      [size]: !!size,
    }"
    @click="onClickHandler"
  >
    <slot v-if="$slots.before && !isLoading" name="before"/>
    <label v-if="label" :style="{ opacity: isLoading ? '0' : '1' }" class="pointer">
      {{ label }}
    </label>
    <slot v-else-if="!isLoading" />
    <slot name="after" />

    <div v-if="isLoading" class="loader-container">
      <UiLoader :radius="10" color="#E1E5F0" :circle-width="2" />
    </div>
  </button>
</template>

<script setup lang="ts">
import {ButtonSizeType, ButtonType} from '~/interfaces/ui/button';
import {useRouter} from '#imports';

type Props = {
  label?: string;
  color?: string;
  type?: ButtonType;
  external?: boolean;
  to?: string | object;
  disabled?: boolean;
  disabledControl?: boolean;
  size?: ButtonSizeType;
  outline?: boolean;
  isLoading?: boolean;
};

const emit = defineEmits(['click']);

const props = withDefaults(defineProps<Props>(), {
  type: ButtonType.primary,
  size: ButtonSizeType.big,
});

const router = useRouter();

function onClickHandler() {
  emit('click');
  if (props.to) {
    router.push(props.to);
  }
}
</script>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  background: transparent;
  border: 0;
  font-size: 14px;
  outline: 0;
  position: relative;
  transition: background-color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1),
    opacity 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);
  cursor: pointer;
  border-radius: 8px;
  font-weight: 600;
  padding-left: 32px;
  padding-right: 32px;

  &.b_xl {
    height: 40px;
    padding: 4px 32px;
  }
  &.big {
    height: 40px;
    padding: 4px 32px;
  }
  &.small {
    height: 32px;
    padding: 6px 24px;
  }
  &.b_m {
    height: 40px;
    border-radius: $br-2;
    padding: $p-2 $p-8 $p-2 $p-8;
    @include button_m;
  }
  &.b_l {
    height: 56px;
    padding: 12px 32px 12px 32px;
    border-radius: 10px;
    @include title-fs-18-fw-600-white-000;
  }
  &.large {
    height: 64px;
    padding: 16px 64px;
  }
  &.x_large {
    height: 80px;
    padding: 16px 64px;
    border-radius: $br-4;
    @include title-fs-18-fw-600-white-000;

    @media (max-width: $md) {
      height: 64px;
    }
  }
  &.xxx_large {
    height: 140px;
    border-radius: $br-4;
  }
  &.transparent {
    background: transparent;
    color: #fff;
    border: 1px solid #fff;
  }
  &-light-gray {
    background: var(--var-light-green-100);
    color: var(--var-blue-800);
    border: 1px solid var(--var-light-green-100);
  }
  &-crimson {
    background: var(--var-crimson-100);
    color: var(--var-white-000);
    border: 1px solid var(--var-crimson-100);
  }
  &-purple {
    background: var(--var-purple-100);
    color: var(--var-white-000);
    border: 1px solid var(--var-white-000);
  }
  &-white {
    background: #fff;
    color: $primary;
    border: 1px solid $primary;
  }
  &-white-primary {
    background-color: var(--var-white-000);
    color: var(--var-blue-50);
    opacity: 0.8;
    transition: all linear 0.25s;
    &:hover {
      border: 1px solid var(--var-blue-100);
      opacity: 1;
      animation: border linear 3s alternate;
    }
  }
  &-primary {
    background: $primary;
    color: #fff;
    opacity: 0.95;
    &:hover {
      opacity: 1;
      transition: none;
    }
  }
  &-create-btn {
    background-color: var(--var-white-200);
    color: var(--var-blue-50);
  }
  &-primary-offer {
    background: var(--var-blue-50);
    color: #fff;
    opacity: 0.95;
    &:hover {
      opacity: 1;
      transition: none;
    }
  }
  &-white-color-black {
    background-color: var(--var-white-000);
    color: var(--var-balck-50);

    &:hover {
      background-color: var(--var-white-100);
    }
  }
  &-grey {
    border: 1px solid #e1e5f0;
    color: var(--white-theme-black-200, #64686e);
    &:hover {
      border: 1px solid #e1e5f0;
      background: #f6f8fd;
    }
  }
  &-gray {
    padding: 4px 32px;
    background: var(--white-theme-white-400, #e1e5f0);
    color: var(--white-theme-black-200, #64686e);
  }
  &-gray-200 {
    background: var(--var-gray-200, #839aaf);
    color: var(--var-white-000, #64686e);
    opacity: 0.95;
    &:hover {
      opacity: 1;
    }
  }
  &-ghost {
    border: 1px solid transparent;
    color: #64686e;
    &:hover {
      border: 1px solid #e1e5f0;
      background: #f6f8fd;
    }
  }
  &-secondary {
    background: #00b440;
    color: #fff;

    &.q-shadow {
      box-shadow: 0 3px 10px #00b44080;
    }
  }

  &--disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
    &_control {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: all;
      &:hover {
        opacity: 0.5;
      }
    }
  }

  &--outlined.button-gray {
    color: #64686e;
    background: transparent;
    border: 1px solid #e1e5f0;
    border-radius: 6px;
  }
  &--outlined.button-primary {
    color: $primary;
    border: 1px solid $primary;
    background: transparent;
    &:hover {
      background: rgba(78, 166, 236, 0.15);
      transition: none;
    }
  }
}
.button--disabled.button-white {
  opacity: 1;
  color: var(--var-blue-400);
  border: 1px solid var(--var-blue-400);
}
.button--disabled.button-create-btn {
  opacity: 1;
  background-color: var(--var-white-200);
  color: var(--var-white-400) !important;
}
.button--disabled_control.button-primary {
  opacity: 1;
  background-color: var(--var-blue-400);
}
.button--disabled.button-primary {
  opacity: 1;
  background-color: var(--var-blue-400);
}

.loader-container {
  position: absolute;
}
</style>
