import {defineStore} from 'pinia';
import type {Cookie} from '~/interfaces';

export const useCookieStore = defineStore('cookie', {
  state: () =>
    ({
      strictly: true,
      performance: true,
      functionality: true,
      marketing: true,
      accept_all_cookie: false,
      error: undefined,
    } as Cookie),
  actions: {
    getCookies() {
      try {
        const cookie_state = localStorage.getItem('accept_cookie');
        if (cookie_state != null) {
          const performance_state = localStorage.getItem(
            'performance_cookie',
          );
          const functionality_state = localStorage.getItem(
            'functionality_cookie',
          );
          const marketing_state = localStorage.getItem(
            'marketing_cookie',
          );
          if (performance_state === 'true') {
            this.performance = true;
          } else {
            this.deleteAllCookie();
          }
          if (functionality_state === 'true') {
            this.functionality = true;
          }
          if (marketing_state === 'true') {
            this.marketing = true;
          }
        }
      } catch (e: any) {
        // console.log()
      }
    },
    acceptCookies(s: boolean, p: boolean, f: boolean, m: boolean) {
      // Cookies.set('accept_cookie', 'accept');
      localStorage.setItem('accept_cookie', 'accept');
      localStorage.setItem('strictly_cookie', s.toString());
      localStorage.setItem('performance_cookie', p.toString());
      localStorage.setItem('functionality_cookie', f.toString());
      localStorage.setItem('marketing_cookie', m.toString());
      this.strictly = s;
      this.accept_all_cookie = true;
      if (p) {
        this.performance = true;
      } else {
        this.performance = false;
        this.deleteAllCookie();
      }
      if (f) {
        this.functionality = true;
      } else {
        this.functionality = false;
        localStorage.clear();
      }
      m ? (this.marketing = true) : (this.marketing = false);
    },
    deleteAllCookie() {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie =
          name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
      }
    },

    // blockMetric(){
    //   let ymCounter = document.querySelector('#ym-counter');
    //   if (ymCounter) {
    //     ymCounter.parentNode.removeChild(ymCounter);
    //   }
    //   let gaScript = document.querySelector('script[src*="google-analytics.com/analytics.js"]');
    //   if (gaScript) {
    //     gaScript.parentNode.removeChild(gaScript);
    //   }
    // },

    // block metrics // custom-mixin
    // export default {
    //   created() {
    //     this.$http.interceptors.push((request, next) => {
    //       if (request.url.includes('example.com')) {
    //         request.cancel()
    //       } else {
    //         next()
    //       }
    //     })
    //   }
    // }
  },
});
