import {defineStore} from 'pinia';
import type {Base} from '~/interfaces';
import {ListLang, ListLangDFlat} from '~/constants';
import {useCookie} from '#imports';
import {getTypeUrl} from '../RealEstate/api';
import Logger from '~/helpers/logger';
import {useRuntimeConfig} from '#imports';
import {useLauncherInfoStore} from '~/store/launcher-info';
import {useUserStore} from '~/modules/User/user.store';
import {createEvent, appDownload} from '~/utils/Analytics';
import {setUtmTags} from '~/helpers/baseFunction';

const logger = Logger.getInstance();
export const useBaseStore = defineStore('base', {
  state: () =>
    ({
      runtimeConfig: useRuntimeConfig(),
      isMobile: useCookie('screen').value === 'mobile',
      currentWidth: 1920,
      isDev: false,
    } as Base),
  getters: {
    listLangCurrent(state) {
      switch (true) {
        case state.runtimeConfig.public.vueAppBaseUrl.includes(
          'd-flat.ru',
        ):
          return ListLangDFlat;
        default:
          return ListLang;
      }
    },
  },
  actions: {
    initializationApp() {
      this.isDev = getTypeUrl();
      const mediaQuery = matchMedia(`(min-width: 1128px)`);
      this.isMobile = !mediaQuery.matches;
      mediaQuery.addEventListener('change', (event) => {
        useCookie('screen').value = !event.matches
          ? 'mobile'
          : 'desktop';
        this.isMobile = !event.matches;
        this.currentWidth = window.innerWidth;
      });
      const mediaSM = matchMedia(`(min-width: 960px)`);
      mediaSM.addEventListener('change', () => {
        this.currentWidth = window.innerWidth;
      });
      const mediaXS = matchMedia(`(min-width: 768px)`);
      mediaXS.addEventListener('change', () => {
        this.currentWidth = window.innerWidth;
      });
      this.currentWidth = window.innerWidth;
      const route = useRoute();
      setUtmTags(route);
    },
    openLauncher(propsString: string) {
      location.href = propsString;
    },
    /* TODO: релиз 19.04.2024 убарть потом */
    downloadLauncher() {
      const user = useUserStore();
      const launcherInfo = useLauncherInfoStore();
      const runtimeConfig = useRuntimeConfig();
      fetch('https://dreamflat.design/stat/count');
      window.location.href = `${runtimeConfig.public.vueAppLauncherDownloadUrl}/${launcherInfo.path}`;
      createEvent(user.$state.user_id, 'app_download');
      appDownload();
      logger.info('[DOWLOAD DF_LAUNCHER] ok');
    },
    downloadInstaller() {
      const user = useUserStore();
      const runtimeConfig = useRuntimeConfig();
      fetch('https://dreamflat.design/stat/count');
      window.location.href = `${runtimeConfig.public.vueAppInstallerDownloadUrl}`;
      createEvent(user.$state.user_id, 'app_download');
      appDownload();
      logger.info('[DOWLOAD DF_LAUNCHER] ok');
    },
  },
});
