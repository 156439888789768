import { default as aboutu8Sb32ihwPMeta } from "/app/pages/about.vue?macro=true";
import { default as cityscapeB4Fv8lIb1eMeta } from "/app/pages/cityscape.vue?macro=true";
import { default as compilation0yjGRVmHiYMeta } from "/app/pages/compilation.vue?macro=true";
import { default as cookie_45policy2bZRk7g5I8Meta } from "/app/pages/cookie-policy.vue?macro=true";
import { default as indexzzYOisnpWbMeta } from "/app/pages/d-studio/create/index.vue?macro=true";
import { default as indexHOXkUEvrMUMeta } from "/app/pages/d-studio/index.vue?macro=true";
import { default as index7A1xxRfM6QMeta } from "/app/pages/d-studio/pay-status/index.vue?macro=true";
import { default as indexOCnOwpIFi3Meta } from "/app/pages/d-studio/pay/index.vue?macro=true";
import { default as indexdpnd4DcXfjMeta } from "/app/pages/d-studio/terms-of-use/index.vue?macro=true";
import { default as indexZQOhDL27HoMeta } from "/app/pages/design-studio/index.vue?macro=true";
import { default as designersQNW9hu68acMeta } from "/app/pages/dubai/designers.vue?macro=true";
import { default as realtybrbbQJQAcUMeta } from "/app/pages/dubai/realty.vue?macro=true";
import { default as dubai2W7R6ZH480DMeta } from "/app/pages/dubai2.vue?macro=true";
import { default as for_45designersL9HRWdjc5oMeta } from "/app/pages/for-designers.vue?macro=true";
import { default as for_45developersM85EQO49RgMeta } from "/app/pages/for-developers.vue?macro=true";
import { default as for_45finishersOwdClBzx5IMeta } from "/app/pages/for-finishers.vue?macro=true";
import { default as for_45furniture_45makersUihflGw9RnMeta } from "/app/pages/for-furniture-makers.vue?macro=true";
import { default as for_45householdsNX1Wvwxt6cMeta } from "/app/pages/for-households.vue?macro=true";
import { default as indexHZTmkntJJcMeta } from "/app/pages/furniture-companies/index.vue?macro=true";
import { default as galleryvsTGmCPX6gMeta } from "/app/pages/gallery.vue?macro=true";
import { default as getsharedJH75BX2jiuMeta } from "/app/pages/getshared.vue?macro=true";
import { default as gitexMoroccocjBvrSaYIUMeta } from "/app/pages/gitexMorocco.vue?macro=true";
import { default as indexamjyqCQoXrMeta } from "/app/pages/help-center/about-dream-flat/index.vue?macro=true";
import { default as indexeHVpFSjFO4Meta } from "/app/pages/help-center/create-content-and-project-demonstration/index.vue?macro=true";
import { default as indexMOeWwviCx6Meta } from "/app/pages/help-center/creating-project/index.vue?macro=true";
import { default as indexNgHTocZnJHMeta } from "/app/pages/help-center/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as launcherlogin622USJAlVYMeta } from "/app/pages/launcherlogin.vue?macro=true";
import { default as license1oPxwXaJfhMeta } from "/app/pages/license.vue?macro=true";
import { default as new8XRwwjkl9dMeta } from "/app/pages/new.vue?macro=true";
import { default as indexTtCmtGQ9NRMeta } from "/app/pages/offer/index.vue?macro=true";
import { default as indextoyI8HEumHMeta } from "/app/pages/offer/pay-status/index.vue?macro=true";
import { default as _91id_93yuH5OfQadmMeta } from "/app/pages/offer/pay/[id].vue?macro=true";
import { default as indexpIDCdxr2vsMeta } from "/app/pages/offer/plans/index.vue?macro=true";
import { default as offer2TwTpaUgg1SMeta } from "/app/pages/offer2.vue?macro=true";
import { default as pay_45status0cljlImIloMeta } from "/app/pages/pay-status.vue?macro=true";
import { default as _91id_93Z8sm1odpbeMeta } from "/app/pages/pay/[id].vue?macro=true";
import { default as plansTUY73vr0OjMeta } from "/app/pages/plans.vue?macro=true";
import { default as privacy_45policyOPxqa3IsSYMeta } from "/app/pages/privacy-policy.vue?macro=true";
import { default as verify_passwordgLQvYSM7fBMeta } from "/app/pages/profile/verify_password.vue?macro=true";
import { default as verify_signupoFYdrbpbWiMeta } from "/app/pages/profile/verify_signup.vue?macro=true";
import { default as profileupBlgdy3vLMeta } from "/app/pages/profile.vue?macro=true";
import { default as real_45estateRBx1btoobbMeta } from "/app/pages/real-estate.vue?macro=true";
import { default as terms_45of_45useA3Owpnmw23Meta } from "/app/pages/terms-of-use.vue?macro=true";
import { default as thank_you_designerOlLS7dGGyrMeta } from "/app/pages/thank_you_designer.vue?macro=true";
import { default as thank_you_developerr71Kq0pOduMeta } from "/app/pages/thank_you_developer.vue?macro=true";
import { default as _91id_93vBwXVvXpAaMeta } from "/app/pages/verify/[id].vue?macro=true";
export default [
  {
    name: aboutu8Sb32ihwPMeta?.name ?? "about",
    path: aboutu8Sb32ihwPMeta?.path ?? "/about",
    meta: aboutu8Sb32ihwPMeta || {},
    alias: aboutu8Sb32ihwPMeta?.alias || [],
    redirect: aboutu8Sb32ihwPMeta?.redirect,
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: cityscapeB4Fv8lIb1eMeta?.name ?? "cityscape",
    path: cityscapeB4Fv8lIb1eMeta?.path ?? "/cityscape",
    meta: cityscapeB4Fv8lIb1eMeta || {},
    alias: cityscapeB4Fv8lIb1eMeta?.alias || [],
    redirect: cityscapeB4Fv8lIb1eMeta?.redirect,
    component: () => import("/app/pages/cityscape.vue").then(m => m.default || m)
  },
  {
    name: compilation0yjGRVmHiYMeta?.name ?? "compilation",
    path: compilation0yjGRVmHiYMeta?.path ?? "/compilation",
    meta: compilation0yjGRVmHiYMeta || {},
    alias: compilation0yjGRVmHiYMeta?.alias || [],
    redirect: compilation0yjGRVmHiYMeta?.redirect,
    component: () => import("/app/pages/compilation.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policy2bZRk7g5I8Meta?.name ?? "cookie-policy",
    path: cookie_45policy2bZRk7g5I8Meta?.path ?? "/cookie-policy",
    meta: cookie_45policy2bZRk7g5I8Meta || {},
    alias: cookie_45policy2bZRk7g5I8Meta?.alias || [],
    redirect: cookie_45policy2bZRk7g5I8Meta?.redirect,
    component: () => import("/app/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: indexzzYOisnpWbMeta?.name ?? "d-studio-create",
    path: indexzzYOisnpWbMeta?.path ?? "/d-studio/create",
    meta: indexzzYOisnpWbMeta || {},
    alias: indexzzYOisnpWbMeta?.alias || [],
    redirect: indexzzYOisnpWbMeta?.redirect,
    component: () => import("/app/pages/d-studio/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexHOXkUEvrMUMeta?.name ?? "d-studio",
    path: indexHOXkUEvrMUMeta?.path ?? "/d-studio",
    meta: indexHOXkUEvrMUMeta || {},
    alias: indexHOXkUEvrMUMeta?.alias || [],
    redirect: indexHOXkUEvrMUMeta?.redirect,
    component: () => import("/app/pages/d-studio/index.vue").then(m => m.default || m)
  },
  {
    name: index7A1xxRfM6QMeta?.name ?? "d-studio-pay-status",
    path: index7A1xxRfM6QMeta?.path ?? "/d-studio/pay-status",
    meta: index7A1xxRfM6QMeta || {},
    alias: index7A1xxRfM6QMeta?.alias || [],
    redirect: index7A1xxRfM6QMeta?.redirect,
    component: () => import("/app/pages/d-studio/pay-status/index.vue").then(m => m.default || m)
  },
  {
    name: indexOCnOwpIFi3Meta?.name ?? "d-studio-pay",
    path: indexOCnOwpIFi3Meta?.path ?? "/d-studio/pay",
    meta: indexOCnOwpIFi3Meta || {},
    alias: indexOCnOwpIFi3Meta?.alias || [],
    redirect: indexOCnOwpIFi3Meta?.redirect,
    component: () => import("/app/pages/d-studio/pay/index.vue").then(m => m.default || m)
  },
  {
    name: indexdpnd4DcXfjMeta?.name ?? "d-studio-terms-of-use",
    path: indexdpnd4DcXfjMeta?.path ?? "/d-studio/terms-of-use",
    meta: indexdpnd4DcXfjMeta || {},
    alias: indexdpnd4DcXfjMeta?.alias || [],
    redirect: indexdpnd4DcXfjMeta?.redirect,
    component: () => import("/app/pages/d-studio/terms-of-use/index.vue").then(m => m.default || m)
  },
  {
    name: indexZQOhDL27HoMeta?.name ?? "design-studio",
    path: indexZQOhDL27HoMeta?.path ?? "/design-studio",
    meta: indexZQOhDL27HoMeta || {},
    alias: indexZQOhDL27HoMeta?.alias || [],
    redirect: indexZQOhDL27HoMeta?.redirect,
    component: () => import("/app/pages/design-studio/index.vue").then(m => m.default || m)
  },
  {
    name: designersQNW9hu68acMeta?.name ?? "dubai-designers",
    path: designersQNW9hu68acMeta?.path ?? "/dubai/designers",
    meta: designersQNW9hu68acMeta || {},
    alias: designersQNW9hu68acMeta?.alias || [],
    redirect: designersQNW9hu68acMeta?.redirect,
    component: () => import("/app/pages/dubai/designers.vue").then(m => m.default || m)
  },
  {
    name: realtybrbbQJQAcUMeta?.name ?? "dubai-realty",
    path: realtybrbbQJQAcUMeta?.path ?? "/dubai/realty",
    meta: realtybrbbQJQAcUMeta || {},
    alias: realtybrbbQJQAcUMeta?.alias || [],
    redirect: realtybrbbQJQAcUMeta?.redirect,
    component: () => import("/app/pages/dubai/realty.vue").then(m => m.default || m)
  },
  {
    name: dubai2W7R6ZH480DMeta?.name ?? "dubai2",
    path: dubai2W7R6ZH480DMeta?.path ?? "/dubai2",
    meta: dubai2W7R6ZH480DMeta || {},
    alias: dubai2W7R6ZH480DMeta?.alias || [],
    redirect: dubai2W7R6ZH480DMeta?.redirect,
    component: () => import("/app/pages/dubai2.vue").then(m => m.default || m)
  },
  {
    name: for_45designersL9HRWdjc5oMeta?.name ?? "for-designers",
    path: for_45designersL9HRWdjc5oMeta?.path ?? "/for-designers",
    meta: for_45designersL9HRWdjc5oMeta || {},
    alias: for_45designersL9HRWdjc5oMeta?.alias || [],
    redirect: for_45designersL9HRWdjc5oMeta?.redirect,
    component: () => import("/app/pages/for-designers.vue").then(m => m.default || m)
  },
  {
    name: for_45developersM85EQO49RgMeta?.name ?? "for-developers",
    path: for_45developersM85EQO49RgMeta?.path ?? "/for-developers",
    meta: for_45developersM85EQO49RgMeta || {},
    alias: for_45developersM85EQO49RgMeta?.alias || [],
    redirect: for_45developersM85EQO49RgMeta?.redirect,
    component: () => import("/app/pages/for-developers.vue").then(m => m.default || m)
  },
  {
    name: for_45finishersOwdClBzx5IMeta?.name ?? "for-finishers",
    path: for_45finishersOwdClBzx5IMeta?.path ?? "/for-finishers",
    meta: for_45finishersOwdClBzx5IMeta || {},
    alias: for_45finishersOwdClBzx5IMeta?.alias || [],
    redirect: for_45finishersOwdClBzx5IMeta?.redirect,
    component: () => import("/app/pages/for-finishers.vue").then(m => m.default || m)
  },
  {
    name: for_45furniture_45makersUihflGw9RnMeta?.name ?? "for-furniture-makers",
    path: for_45furniture_45makersUihflGw9RnMeta?.path ?? "/for-furniture-makers",
    meta: for_45furniture_45makersUihflGw9RnMeta || {},
    alias: for_45furniture_45makersUihflGw9RnMeta?.alias || [],
    redirect: for_45furniture_45makersUihflGw9RnMeta?.redirect,
    component: () => import("/app/pages/for-furniture-makers.vue").then(m => m.default || m)
  },
  {
    name: for_45householdsNX1Wvwxt6cMeta?.name ?? "for-households",
    path: for_45householdsNX1Wvwxt6cMeta?.path ?? "/for-households",
    meta: for_45householdsNX1Wvwxt6cMeta || {},
    alias: for_45householdsNX1Wvwxt6cMeta?.alias || [],
    redirect: for_45householdsNX1Wvwxt6cMeta?.redirect,
    component: () => import("/app/pages/for-households.vue").then(m => m.default || m)
  },
  {
    name: indexHZTmkntJJcMeta?.name ?? "furniture-companies",
    path: indexHZTmkntJJcMeta?.path ?? "/furniture-companies",
    meta: indexHZTmkntJJcMeta || {},
    alias: indexHZTmkntJJcMeta?.alias || [],
    redirect: indexHZTmkntJJcMeta?.redirect,
    component: () => import("/app/pages/furniture-companies/index.vue").then(m => m.default || m)
  },
  {
    name: galleryvsTGmCPX6gMeta?.name ?? "gallery",
    path: galleryvsTGmCPX6gMeta?.path ?? "/gallery",
    meta: galleryvsTGmCPX6gMeta || {},
    alias: galleryvsTGmCPX6gMeta?.alias || [],
    redirect: galleryvsTGmCPX6gMeta?.redirect,
    component: () => import("/app/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: getsharedJH75BX2jiuMeta?.name ?? "getshared",
    path: getsharedJH75BX2jiuMeta?.path ?? "/getshared",
    meta: getsharedJH75BX2jiuMeta || {},
    alias: getsharedJH75BX2jiuMeta?.alias || [],
    redirect: getsharedJH75BX2jiuMeta?.redirect,
    component: () => import("/app/pages/getshared.vue").then(m => m.default || m)
  },
  {
    name: gitexMoroccocjBvrSaYIUMeta?.name ?? "gitexMorocco",
    path: gitexMoroccocjBvrSaYIUMeta?.path ?? "/gitexMorocco",
    meta: gitexMoroccocjBvrSaYIUMeta || {},
    alias: gitexMoroccocjBvrSaYIUMeta?.alias || [],
    redirect: gitexMoroccocjBvrSaYIUMeta?.redirect,
    component: () => import("/app/pages/gitexMorocco.vue").then(m => m.default || m)
  },
  {
    name: indexamjyqCQoXrMeta?.name ?? "help-center-about-dream-flat",
    path: indexamjyqCQoXrMeta?.path ?? "/help-center/about-dream-flat",
    meta: indexamjyqCQoXrMeta || {},
    alias: indexamjyqCQoXrMeta?.alias || [],
    redirect: indexamjyqCQoXrMeta?.redirect,
    component: () => import("/app/pages/help-center/about-dream-flat/index.vue").then(m => m.default || m)
  },
  {
    name: indexeHVpFSjFO4Meta?.name ?? "help-center-create-content-and-project-demonstration",
    path: indexeHVpFSjFO4Meta?.path ?? "/help-center/create-content-and-project-demonstration",
    meta: indexeHVpFSjFO4Meta || {},
    alias: indexeHVpFSjFO4Meta?.alias || [],
    redirect: indexeHVpFSjFO4Meta?.redirect,
    component: () => import("/app/pages/help-center/create-content-and-project-demonstration/index.vue").then(m => m.default || m)
  },
  {
    name: indexMOeWwviCx6Meta?.name ?? "help-center-creating-project",
    path: indexMOeWwviCx6Meta?.path ?? "/help-center/creating-project",
    meta: indexMOeWwviCx6Meta || {},
    alias: indexMOeWwviCx6Meta?.alias || [],
    redirect: indexMOeWwviCx6Meta?.redirect,
    component: () => import("/app/pages/help-center/creating-project/index.vue").then(m => m.default || m)
  },
  {
    name: indexNgHTocZnJHMeta?.name ?? "help-center",
    path: indexNgHTocZnJHMeta?.path ?? "/help-center",
    meta: indexNgHTocZnJHMeta || {},
    alias: indexNgHTocZnJHMeta?.alias || [],
    redirect: indexNgHTocZnJHMeta?.redirect,
    component: () => import("/app/pages/help-center/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: launcherlogin622USJAlVYMeta?.name ?? "launcherlogin",
    path: launcherlogin622USJAlVYMeta?.path ?? "/launcherlogin",
    meta: launcherlogin622USJAlVYMeta || {},
    alias: launcherlogin622USJAlVYMeta?.alias || [],
    redirect: launcherlogin622USJAlVYMeta?.redirect,
    component: () => import("/app/pages/launcherlogin.vue").then(m => m.default || m)
  },
  {
    name: license1oPxwXaJfhMeta?.name ?? "license",
    path: license1oPxwXaJfhMeta?.path ?? "/license",
    meta: license1oPxwXaJfhMeta || {},
    alias: license1oPxwXaJfhMeta?.alias || [],
    redirect: license1oPxwXaJfhMeta?.redirect,
    component: () => import("/app/pages/license.vue").then(m => m.default || m)
  },
  {
    name: new8XRwwjkl9dMeta?.name ?? "new",
    path: new8XRwwjkl9dMeta?.path ?? "/new",
    meta: new8XRwwjkl9dMeta || {},
    alias: new8XRwwjkl9dMeta?.alias || [],
    redirect: new8XRwwjkl9dMeta?.redirect,
    component: () => import("/app/pages/new.vue").then(m => m.default || m)
  },
  {
    name: indexTtCmtGQ9NRMeta?.name ?? "offer",
    path: indexTtCmtGQ9NRMeta?.path ?? "/offer",
    meta: indexTtCmtGQ9NRMeta || {},
    alias: indexTtCmtGQ9NRMeta?.alias || [],
    redirect: indexTtCmtGQ9NRMeta?.redirect,
    component: () => import("/app/pages/offer/index.vue").then(m => m.default || m)
  },
  {
    name: indextoyI8HEumHMeta?.name ?? "offer-pay-status",
    path: indextoyI8HEumHMeta?.path ?? "/offer/pay-status",
    meta: indextoyI8HEumHMeta || {},
    alias: indextoyI8HEumHMeta?.alias || [],
    redirect: indextoyI8HEumHMeta?.redirect,
    component: () => import("/app/pages/offer/pay-status/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93yuH5OfQadmMeta?.name ?? "offer-pay-id",
    path: _91id_93yuH5OfQadmMeta?.path ?? "/offer/pay/:id()",
    meta: _91id_93yuH5OfQadmMeta || {},
    alias: _91id_93yuH5OfQadmMeta?.alias || [],
    redirect: _91id_93yuH5OfQadmMeta?.redirect,
    component: () => import("/app/pages/offer/pay/[id].vue").then(m => m.default || m)
  },
  {
    name: indexpIDCdxr2vsMeta?.name ?? "offer-plans",
    path: indexpIDCdxr2vsMeta?.path ?? "/offer/plans",
    meta: indexpIDCdxr2vsMeta || {},
    alias: indexpIDCdxr2vsMeta?.alias || [],
    redirect: indexpIDCdxr2vsMeta?.redirect,
    component: () => import("/app/pages/offer/plans/index.vue").then(m => m.default || m)
  },
  {
    name: offer2TwTpaUgg1SMeta?.name ?? "offer2",
    path: offer2TwTpaUgg1SMeta?.path ?? "/offer2",
    meta: offer2TwTpaUgg1SMeta || {},
    alias: offer2TwTpaUgg1SMeta?.alias || [],
    redirect: offer2TwTpaUgg1SMeta?.redirect,
    component: () => import("/app/pages/offer2.vue").then(m => m.default || m)
  },
  {
    name: pay_45status0cljlImIloMeta?.name ?? "pay-status",
    path: pay_45status0cljlImIloMeta?.path ?? "/pay-status",
    meta: pay_45status0cljlImIloMeta || {},
    alias: pay_45status0cljlImIloMeta?.alias || [],
    redirect: pay_45status0cljlImIloMeta?.redirect,
    component: () => import("/app/pages/pay-status.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Z8sm1odpbeMeta?.name ?? "pay-id",
    path: _91id_93Z8sm1odpbeMeta?.path ?? "/pay/:id()",
    meta: _91id_93Z8sm1odpbeMeta || {},
    alias: _91id_93Z8sm1odpbeMeta?.alias || [],
    redirect: _91id_93Z8sm1odpbeMeta?.redirect,
    component: () => import("/app/pages/pay/[id].vue").then(m => m.default || m)
  },
  {
    name: plansTUY73vr0OjMeta?.name ?? "plans",
    path: plansTUY73vr0OjMeta?.path ?? "/plans",
    meta: plansTUY73vr0OjMeta || {},
    alias: plansTUY73vr0OjMeta?.alias || [],
    redirect: plansTUY73vr0OjMeta?.redirect,
    component: () => import("/app/pages/plans.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyOPxqa3IsSYMeta?.name ?? "privacy-policy",
    path: privacy_45policyOPxqa3IsSYMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyOPxqa3IsSYMeta || {},
    alias: privacy_45policyOPxqa3IsSYMeta?.alias || [],
    redirect: privacy_45policyOPxqa3IsSYMeta?.redirect,
    component: () => import("/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: profileupBlgdy3vLMeta?.name ?? "profile",
    path: profileupBlgdy3vLMeta?.path ?? "/profile",
    meta: profileupBlgdy3vLMeta || {},
    alias: profileupBlgdy3vLMeta?.alias || [],
    redirect: profileupBlgdy3vLMeta?.redirect,
    component: () => import("/app/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: verify_passwordgLQvYSM7fBMeta?.name ?? "profile-verify_password",
    path: verify_passwordgLQvYSM7fBMeta?.path ?? "verify_password",
    meta: verify_passwordgLQvYSM7fBMeta || {},
    alias: verify_passwordgLQvYSM7fBMeta?.alias || [],
    redirect: verify_passwordgLQvYSM7fBMeta?.redirect,
    component: () => import("/app/pages/profile/verify_password.vue").then(m => m.default || m)
  },
  {
    name: verify_signupoFYdrbpbWiMeta?.name ?? "profile-verify_signup",
    path: verify_signupoFYdrbpbWiMeta?.path ?? "verify_signup",
    meta: verify_signupoFYdrbpbWiMeta || {},
    alias: verify_signupoFYdrbpbWiMeta?.alias || [],
    redirect: verify_signupoFYdrbpbWiMeta?.redirect,
    component: () => import("/app/pages/profile/verify_signup.vue").then(m => m.default || m)
  }
]
  },
  {
    name: real_45estateRBx1btoobbMeta?.name ?? "real-estate",
    path: real_45estateRBx1btoobbMeta?.path ?? "/real-estate",
    meta: real_45estateRBx1btoobbMeta || {},
    alias: real_45estateRBx1btoobbMeta?.alias || [],
    redirect: real_45estateRBx1btoobbMeta?.redirect,
    component: () => import("/app/pages/real-estate.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45useA3Owpnmw23Meta?.name ?? "terms-of-use",
    path: terms_45of_45useA3Owpnmw23Meta?.path ?? "/terms-of-use",
    meta: terms_45of_45useA3Owpnmw23Meta || {},
    alias: terms_45of_45useA3Owpnmw23Meta?.alias || [],
    redirect: terms_45of_45useA3Owpnmw23Meta?.redirect,
    component: () => import("/app/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: thank_you_designerOlLS7dGGyrMeta?.name ?? "thank_you_designer",
    path: thank_you_designerOlLS7dGGyrMeta?.path ?? "/thank_you_designer",
    meta: thank_you_designerOlLS7dGGyrMeta || {},
    alias: thank_you_designerOlLS7dGGyrMeta?.alias || [],
    redirect: thank_you_designerOlLS7dGGyrMeta?.redirect,
    component: () => import("/app/pages/thank_you_designer.vue").then(m => m.default || m)
  },
  {
    name: thank_you_developerr71Kq0pOduMeta?.name ?? "thank_you_developer",
    path: thank_you_developerr71Kq0pOduMeta?.path ?? "/thank_you_developer",
    meta: thank_you_developerr71Kq0pOduMeta || {},
    alias: thank_you_developerr71Kq0pOduMeta?.alias || [],
    redirect: thank_you_developerr71Kq0pOduMeta?.redirect,
    component: () => import("/app/pages/thank_you_developer.vue").then(m => m.default || m)
  },
  {
    name: _91id_93vBwXVvXpAaMeta?.name ?? "verify-id",
    path: _91id_93vBwXVvXpAaMeta?.path ?? "/verify/:id()",
    meta: _91id_93vBwXVvXpAaMeta || {},
    alias: _91id_93vBwXVvXpAaMeta?.alias || [],
    redirect: _91id_93vBwXVvXpAaMeta?.redirect,
    component: () => import("/app/pages/verify/[id].vue").then(m => m.default || m)
  },
  {
    name: aboutu8Sb32ihwPMeta?.name ?? "about___en",
    path: aboutu8Sb32ihwPMeta?.path ?? "/en/about",
    meta: aboutu8Sb32ihwPMeta || {},
    alias: aboutu8Sb32ihwPMeta?.alias || [],
    redirect: aboutu8Sb32ihwPMeta?.redirect,
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutu8Sb32ihwPMeta?.name ?? "about___es",
    path: aboutu8Sb32ihwPMeta?.path ?? "/es/about",
    meta: aboutu8Sb32ihwPMeta || {},
    alias: aboutu8Sb32ihwPMeta?.alias || [],
    redirect: aboutu8Sb32ihwPMeta?.redirect,
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutu8Sb32ihwPMeta?.name ?? "about___ru",
    path: aboutu8Sb32ihwPMeta?.path ?? "/ru/about",
    meta: aboutu8Sb32ihwPMeta || {},
    alias: aboutu8Sb32ihwPMeta?.alias || [],
    redirect: aboutu8Sb32ihwPMeta?.redirect,
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutu8Sb32ihwPMeta?.name ?? "about___fr",
    path: aboutu8Sb32ihwPMeta?.path ?? "/fr/about",
    meta: aboutu8Sb32ihwPMeta || {},
    alias: aboutu8Sb32ihwPMeta?.alias || [],
    redirect: aboutu8Sb32ihwPMeta?.redirect,
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutu8Sb32ihwPMeta?.name ?? "about___ar",
    path: aboutu8Sb32ihwPMeta?.path ?? "/ar/about",
    meta: aboutu8Sb32ihwPMeta || {},
    alias: aboutu8Sb32ihwPMeta?.alias || [],
    redirect: aboutu8Sb32ihwPMeta?.redirect,
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: cityscapeB4Fv8lIb1eMeta?.name ?? "cityscape___en",
    path: cityscapeB4Fv8lIb1eMeta?.path ?? "/en/cityscape",
    meta: cityscapeB4Fv8lIb1eMeta || {},
    alias: cityscapeB4Fv8lIb1eMeta?.alias || [],
    redirect: cityscapeB4Fv8lIb1eMeta?.redirect,
    component: () => import("/app/pages/cityscape.vue").then(m => m.default || m)
  },
  {
    name: cityscapeB4Fv8lIb1eMeta?.name ?? "cityscape___es",
    path: cityscapeB4Fv8lIb1eMeta?.path ?? "/es/cityscape",
    meta: cityscapeB4Fv8lIb1eMeta || {},
    alias: cityscapeB4Fv8lIb1eMeta?.alias || [],
    redirect: cityscapeB4Fv8lIb1eMeta?.redirect,
    component: () => import("/app/pages/cityscape.vue").then(m => m.default || m)
  },
  {
    name: cityscapeB4Fv8lIb1eMeta?.name ?? "cityscape___ru",
    path: cityscapeB4Fv8lIb1eMeta?.path ?? "/ru/cityscape",
    meta: cityscapeB4Fv8lIb1eMeta || {},
    alias: cityscapeB4Fv8lIb1eMeta?.alias || [],
    redirect: cityscapeB4Fv8lIb1eMeta?.redirect,
    component: () => import("/app/pages/cityscape.vue").then(m => m.default || m)
  },
  {
    name: cityscapeB4Fv8lIb1eMeta?.name ?? "cityscape___fr",
    path: cityscapeB4Fv8lIb1eMeta?.path ?? "/fr/cityscape",
    meta: cityscapeB4Fv8lIb1eMeta || {},
    alias: cityscapeB4Fv8lIb1eMeta?.alias || [],
    redirect: cityscapeB4Fv8lIb1eMeta?.redirect,
    component: () => import("/app/pages/cityscape.vue").then(m => m.default || m)
  },
  {
    name: cityscapeB4Fv8lIb1eMeta?.name ?? "cityscape___ar",
    path: cityscapeB4Fv8lIb1eMeta?.path ?? "/ar/cityscape",
    meta: cityscapeB4Fv8lIb1eMeta || {},
    alias: cityscapeB4Fv8lIb1eMeta?.alias || [],
    redirect: cityscapeB4Fv8lIb1eMeta?.redirect,
    component: () => import("/app/pages/cityscape.vue").then(m => m.default || m)
  },
  {
    name: compilation0yjGRVmHiYMeta?.name ?? "compilation___en",
    path: compilation0yjGRVmHiYMeta?.path ?? "/en/compilation",
    meta: compilation0yjGRVmHiYMeta || {},
    alias: compilation0yjGRVmHiYMeta?.alias || [],
    redirect: compilation0yjGRVmHiYMeta?.redirect,
    component: () => import("/app/pages/compilation.vue").then(m => m.default || m)
  },
  {
    name: compilation0yjGRVmHiYMeta?.name ?? "compilation___es",
    path: compilation0yjGRVmHiYMeta?.path ?? "/es/compilation",
    meta: compilation0yjGRVmHiYMeta || {},
    alias: compilation0yjGRVmHiYMeta?.alias || [],
    redirect: compilation0yjGRVmHiYMeta?.redirect,
    component: () => import("/app/pages/compilation.vue").then(m => m.default || m)
  },
  {
    name: compilation0yjGRVmHiYMeta?.name ?? "compilation___ru",
    path: compilation0yjGRVmHiYMeta?.path ?? "/ru/compilation",
    meta: compilation0yjGRVmHiYMeta || {},
    alias: compilation0yjGRVmHiYMeta?.alias || [],
    redirect: compilation0yjGRVmHiYMeta?.redirect,
    component: () => import("/app/pages/compilation.vue").then(m => m.default || m)
  },
  {
    name: compilation0yjGRVmHiYMeta?.name ?? "compilation___fr",
    path: compilation0yjGRVmHiYMeta?.path ?? "/fr/compilation",
    meta: compilation0yjGRVmHiYMeta || {},
    alias: compilation0yjGRVmHiYMeta?.alias || [],
    redirect: compilation0yjGRVmHiYMeta?.redirect,
    component: () => import("/app/pages/compilation.vue").then(m => m.default || m)
  },
  {
    name: compilation0yjGRVmHiYMeta?.name ?? "compilation___ar",
    path: compilation0yjGRVmHiYMeta?.path ?? "/ar/compilation",
    meta: compilation0yjGRVmHiYMeta || {},
    alias: compilation0yjGRVmHiYMeta?.alias || [],
    redirect: compilation0yjGRVmHiYMeta?.redirect,
    component: () => import("/app/pages/compilation.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policy2bZRk7g5I8Meta?.name ?? "cookie-policy___en",
    path: cookie_45policy2bZRk7g5I8Meta?.path ?? "/en/cookie-policy",
    meta: cookie_45policy2bZRk7g5I8Meta || {},
    alias: cookie_45policy2bZRk7g5I8Meta?.alias || [],
    redirect: cookie_45policy2bZRk7g5I8Meta?.redirect,
    component: () => import("/app/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policy2bZRk7g5I8Meta?.name ?? "cookie-policy___es",
    path: cookie_45policy2bZRk7g5I8Meta?.path ?? "/es/cookie-policy",
    meta: cookie_45policy2bZRk7g5I8Meta || {},
    alias: cookie_45policy2bZRk7g5I8Meta?.alias || [],
    redirect: cookie_45policy2bZRk7g5I8Meta?.redirect,
    component: () => import("/app/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policy2bZRk7g5I8Meta?.name ?? "cookie-policy___ru",
    path: cookie_45policy2bZRk7g5I8Meta?.path ?? "/ru/cookie-policy",
    meta: cookie_45policy2bZRk7g5I8Meta || {},
    alias: cookie_45policy2bZRk7g5I8Meta?.alias || [],
    redirect: cookie_45policy2bZRk7g5I8Meta?.redirect,
    component: () => import("/app/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policy2bZRk7g5I8Meta?.name ?? "cookie-policy___fr",
    path: cookie_45policy2bZRk7g5I8Meta?.path ?? "/fr/cookie-policy",
    meta: cookie_45policy2bZRk7g5I8Meta || {},
    alias: cookie_45policy2bZRk7g5I8Meta?.alias || [],
    redirect: cookie_45policy2bZRk7g5I8Meta?.redirect,
    component: () => import("/app/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policy2bZRk7g5I8Meta?.name ?? "cookie-policy___ar",
    path: cookie_45policy2bZRk7g5I8Meta?.path ?? "/ar/cookie-policy",
    meta: cookie_45policy2bZRk7g5I8Meta || {},
    alias: cookie_45policy2bZRk7g5I8Meta?.alias || [],
    redirect: cookie_45policy2bZRk7g5I8Meta?.redirect,
    component: () => import("/app/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: indexzzYOisnpWbMeta?.name ?? "d-studio-create___en",
    path: indexzzYOisnpWbMeta?.path ?? "/en/d-studio/create",
    meta: indexzzYOisnpWbMeta || {},
    alias: indexzzYOisnpWbMeta?.alias || [],
    redirect: indexzzYOisnpWbMeta?.redirect,
    component: () => import("/app/pages/d-studio/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexzzYOisnpWbMeta?.name ?? "d-studio-create___es",
    path: indexzzYOisnpWbMeta?.path ?? "/es/d-studio/create",
    meta: indexzzYOisnpWbMeta || {},
    alias: indexzzYOisnpWbMeta?.alias || [],
    redirect: indexzzYOisnpWbMeta?.redirect,
    component: () => import("/app/pages/d-studio/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexzzYOisnpWbMeta?.name ?? "d-studio-create___ru",
    path: indexzzYOisnpWbMeta?.path ?? "/ru/d-studio/create",
    meta: indexzzYOisnpWbMeta || {},
    alias: indexzzYOisnpWbMeta?.alias || [],
    redirect: indexzzYOisnpWbMeta?.redirect,
    component: () => import("/app/pages/d-studio/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexzzYOisnpWbMeta?.name ?? "d-studio-create___fr",
    path: indexzzYOisnpWbMeta?.path ?? "/fr/d-studio/create",
    meta: indexzzYOisnpWbMeta || {},
    alias: indexzzYOisnpWbMeta?.alias || [],
    redirect: indexzzYOisnpWbMeta?.redirect,
    component: () => import("/app/pages/d-studio/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexzzYOisnpWbMeta?.name ?? "d-studio-create___ar",
    path: indexzzYOisnpWbMeta?.path ?? "/ar/d-studio/create",
    meta: indexzzYOisnpWbMeta || {},
    alias: indexzzYOisnpWbMeta?.alias || [],
    redirect: indexzzYOisnpWbMeta?.redirect,
    component: () => import("/app/pages/d-studio/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexHOXkUEvrMUMeta?.name ?? "d-studio___en",
    path: indexHOXkUEvrMUMeta?.path ?? "/en/d-studio",
    meta: indexHOXkUEvrMUMeta || {},
    alias: indexHOXkUEvrMUMeta?.alias || [],
    redirect: indexHOXkUEvrMUMeta?.redirect,
    component: () => import("/app/pages/d-studio/index.vue").then(m => m.default || m)
  },
  {
    name: indexHOXkUEvrMUMeta?.name ?? "d-studio___es",
    path: indexHOXkUEvrMUMeta?.path ?? "/es/d-studio",
    meta: indexHOXkUEvrMUMeta || {},
    alias: indexHOXkUEvrMUMeta?.alias || [],
    redirect: indexHOXkUEvrMUMeta?.redirect,
    component: () => import("/app/pages/d-studio/index.vue").then(m => m.default || m)
  },
  {
    name: indexHOXkUEvrMUMeta?.name ?? "d-studio___ru",
    path: indexHOXkUEvrMUMeta?.path ?? "/ru/d-studio",
    meta: indexHOXkUEvrMUMeta || {},
    alias: indexHOXkUEvrMUMeta?.alias || [],
    redirect: indexHOXkUEvrMUMeta?.redirect,
    component: () => import("/app/pages/d-studio/index.vue").then(m => m.default || m)
  },
  {
    name: indexHOXkUEvrMUMeta?.name ?? "d-studio___fr",
    path: indexHOXkUEvrMUMeta?.path ?? "/fr/d-studio",
    meta: indexHOXkUEvrMUMeta || {},
    alias: indexHOXkUEvrMUMeta?.alias || [],
    redirect: indexHOXkUEvrMUMeta?.redirect,
    component: () => import("/app/pages/d-studio/index.vue").then(m => m.default || m)
  },
  {
    name: indexHOXkUEvrMUMeta?.name ?? "d-studio___ar",
    path: indexHOXkUEvrMUMeta?.path ?? "/ar/d-studio",
    meta: indexHOXkUEvrMUMeta || {},
    alias: indexHOXkUEvrMUMeta?.alias || [],
    redirect: indexHOXkUEvrMUMeta?.redirect,
    component: () => import("/app/pages/d-studio/index.vue").then(m => m.default || m)
  },
  {
    name: index7A1xxRfM6QMeta?.name ?? "d-studio-pay-status___en",
    path: index7A1xxRfM6QMeta?.path ?? "/en/d-studio/pay-status",
    meta: index7A1xxRfM6QMeta || {},
    alias: index7A1xxRfM6QMeta?.alias || [],
    redirect: index7A1xxRfM6QMeta?.redirect,
    component: () => import("/app/pages/d-studio/pay-status/index.vue").then(m => m.default || m)
  },
  {
    name: index7A1xxRfM6QMeta?.name ?? "d-studio-pay-status___es",
    path: index7A1xxRfM6QMeta?.path ?? "/es/d-studio/pay-status",
    meta: index7A1xxRfM6QMeta || {},
    alias: index7A1xxRfM6QMeta?.alias || [],
    redirect: index7A1xxRfM6QMeta?.redirect,
    component: () => import("/app/pages/d-studio/pay-status/index.vue").then(m => m.default || m)
  },
  {
    name: index7A1xxRfM6QMeta?.name ?? "d-studio-pay-status___ru",
    path: index7A1xxRfM6QMeta?.path ?? "/ru/d-studio/pay-status",
    meta: index7A1xxRfM6QMeta || {},
    alias: index7A1xxRfM6QMeta?.alias || [],
    redirect: index7A1xxRfM6QMeta?.redirect,
    component: () => import("/app/pages/d-studio/pay-status/index.vue").then(m => m.default || m)
  },
  {
    name: index7A1xxRfM6QMeta?.name ?? "d-studio-pay-status___fr",
    path: index7A1xxRfM6QMeta?.path ?? "/fr/d-studio/pay-status",
    meta: index7A1xxRfM6QMeta || {},
    alias: index7A1xxRfM6QMeta?.alias || [],
    redirect: index7A1xxRfM6QMeta?.redirect,
    component: () => import("/app/pages/d-studio/pay-status/index.vue").then(m => m.default || m)
  },
  {
    name: index7A1xxRfM6QMeta?.name ?? "d-studio-pay-status___ar",
    path: index7A1xxRfM6QMeta?.path ?? "/ar/d-studio/pay-status",
    meta: index7A1xxRfM6QMeta || {},
    alias: index7A1xxRfM6QMeta?.alias || [],
    redirect: index7A1xxRfM6QMeta?.redirect,
    component: () => import("/app/pages/d-studio/pay-status/index.vue").then(m => m.default || m)
  },
  {
    name: indexOCnOwpIFi3Meta?.name ?? "d-studio-pay___en",
    path: indexOCnOwpIFi3Meta?.path ?? "/en/d-studio/pay",
    meta: indexOCnOwpIFi3Meta || {},
    alias: indexOCnOwpIFi3Meta?.alias || [],
    redirect: indexOCnOwpIFi3Meta?.redirect,
    component: () => import("/app/pages/d-studio/pay/index.vue").then(m => m.default || m)
  },
  {
    name: indexOCnOwpIFi3Meta?.name ?? "d-studio-pay___es",
    path: indexOCnOwpIFi3Meta?.path ?? "/es/d-studio/pay",
    meta: indexOCnOwpIFi3Meta || {},
    alias: indexOCnOwpIFi3Meta?.alias || [],
    redirect: indexOCnOwpIFi3Meta?.redirect,
    component: () => import("/app/pages/d-studio/pay/index.vue").then(m => m.default || m)
  },
  {
    name: indexOCnOwpIFi3Meta?.name ?? "d-studio-pay___ru",
    path: indexOCnOwpIFi3Meta?.path ?? "/ru/d-studio/pay",
    meta: indexOCnOwpIFi3Meta || {},
    alias: indexOCnOwpIFi3Meta?.alias || [],
    redirect: indexOCnOwpIFi3Meta?.redirect,
    component: () => import("/app/pages/d-studio/pay/index.vue").then(m => m.default || m)
  },
  {
    name: indexOCnOwpIFi3Meta?.name ?? "d-studio-pay___fr",
    path: indexOCnOwpIFi3Meta?.path ?? "/fr/d-studio/pay",
    meta: indexOCnOwpIFi3Meta || {},
    alias: indexOCnOwpIFi3Meta?.alias || [],
    redirect: indexOCnOwpIFi3Meta?.redirect,
    component: () => import("/app/pages/d-studio/pay/index.vue").then(m => m.default || m)
  },
  {
    name: indexOCnOwpIFi3Meta?.name ?? "d-studio-pay___ar",
    path: indexOCnOwpIFi3Meta?.path ?? "/ar/d-studio/pay",
    meta: indexOCnOwpIFi3Meta || {},
    alias: indexOCnOwpIFi3Meta?.alias || [],
    redirect: indexOCnOwpIFi3Meta?.redirect,
    component: () => import("/app/pages/d-studio/pay/index.vue").then(m => m.default || m)
  },
  {
    name: indexdpnd4DcXfjMeta?.name ?? "d-studio-terms-of-use___en",
    path: indexdpnd4DcXfjMeta?.path ?? "/en/d-studio/terms-of-use",
    meta: indexdpnd4DcXfjMeta || {},
    alias: indexdpnd4DcXfjMeta?.alias || [],
    redirect: indexdpnd4DcXfjMeta?.redirect,
    component: () => import("/app/pages/d-studio/terms-of-use/index.vue").then(m => m.default || m)
  },
  {
    name: indexdpnd4DcXfjMeta?.name ?? "d-studio-terms-of-use___es",
    path: indexdpnd4DcXfjMeta?.path ?? "/es/d-studio/terms-of-use",
    meta: indexdpnd4DcXfjMeta || {},
    alias: indexdpnd4DcXfjMeta?.alias || [],
    redirect: indexdpnd4DcXfjMeta?.redirect,
    component: () => import("/app/pages/d-studio/terms-of-use/index.vue").then(m => m.default || m)
  },
  {
    name: indexdpnd4DcXfjMeta?.name ?? "d-studio-terms-of-use___ru",
    path: indexdpnd4DcXfjMeta?.path ?? "/ru/d-studio/terms-of-use",
    meta: indexdpnd4DcXfjMeta || {},
    alias: indexdpnd4DcXfjMeta?.alias || [],
    redirect: indexdpnd4DcXfjMeta?.redirect,
    component: () => import("/app/pages/d-studio/terms-of-use/index.vue").then(m => m.default || m)
  },
  {
    name: indexdpnd4DcXfjMeta?.name ?? "d-studio-terms-of-use___fr",
    path: indexdpnd4DcXfjMeta?.path ?? "/fr/d-studio/terms-of-use",
    meta: indexdpnd4DcXfjMeta || {},
    alias: indexdpnd4DcXfjMeta?.alias || [],
    redirect: indexdpnd4DcXfjMeta?.redirect,
    component: () => import("/app/pages/d-studio/terms-of-use/index.vue").then(m => m.default || m)
  },
  {
    name: indexdpnd4DcXfjMeta?.name ?? "d-studio-terms-of-use___ar",
    path: indexdpnd4DcXfjMeta?.path ?? "/ar/d-studio/terms-of-use",
    meta: indexdpnd4DcXfjMeta || {},
    alias: indexdpnd4DcXfjMeta?.alias || [],
    redirect: indexdpnd4DcXfjMeta?.redirect,
    component: () => import("/app/pages/d-studio/terms-of-use/index.vue").then(m => m.default || m)
  },
  {
    name: indexZQOhDL27HoMeta?.name ?? "design-studio___en",
    path: indexZQOhDL27HoMeta?.path ?? "/en/design-studio",
    meta: indexZQOhDL27HoMeta || {},
    alias: indexZQOhDL27HoMeta?.alias || [],
    redirect: indexZQOhDL27HoMeta?.redirect,
    component: () => import("/app/pages/design-studio/index.vue").then(m => m.default || m)
  },
  {
    name: indexZQOhDL27HoMeta?.name ?? "design-studio___es",
    path: indexZQOhDL27HoMeta?.path ?? "/es/design-studio",
    meta: indexZQOhDL27HoMeta || {},
    alias: indexZQOhDL27HoMeta?.alias || [],
    redirect: indexZQOhDL27HoMeta?.redirect,
    component: () => import("/app/pages/design-studio/index.vue").then(m => m.default || m)
  },
  {
    name: indexZQOhDL27HoMeta?.name ?? "design-studio___ru",
    path: indexZQOhDL27HoMeta?.path ?? "/ru/design-studio",
    meta: indexZQOhDL27HoMeta || {},
    alias: indexZQOhDL27HoMeta?.alias || [],
    redirect: indexZQOhDL27HoMeta?.redirect,
    component: () => import("/app/pages/design-studio/index.vue").then(m => m.default || m)
  },
  {
    name: indexZQOhDL27HoMeta?.name ?? "design-studio___fr",
    path: indexZQOhDL27HoMeta?.path ?? "/fr/design-studio",
    meta: indexZQOhDL27HoMeta || {},
    alias: indexZQOhDL27HoMeta?.alias || [],
    redirect: indexZQOhDL27HoMeta?.redirect,
    component: () => import("/app/pages/design-studio/index.vue").then(m => m.default || m)
  },
  {
    name: indexZQOhDL27HoMeta?.name ?? "design-studio___ar",
    path: indexZQOhDL27HoMeta?.path ?? "/ar/design-studio",
    meta: indexZQOhDL27HoMeta || {},
    alias: indexZQOhDL27HoMeta?.alias || [],
    redirect: indexZQOhDL27HoMeta?.redirect,
    component: () => import("/app/pages/design-studio/index.vue").then(m => m.default || m)
  },
  {
    name: designersQNW9hu68acMeta?.name ?? "dubai-designers___en",
    path: designersQNW9hu68acMeta?.path ?? "/en/dubai/designers",
    meta: designersQNW9hu68acMeta || {},
    alias: designersQNW9hu68acMeta?.alias || [],
    redirect: designersQNW9hu68acMeta?.redirect,
    component: () => import("/app/pages/dubai/designers.vue").then(m => m.default || m)
  },
  {
    name: designersQNW9hu68acMeta?.name ?? "dubai-designers___es",
    path: designersQNW9hu68acMeta?.path ?? "/es/dubai/designers",
    meta: designersQNW9hu68acMeta || {},
    alias: designersQNW9hu68acMeta?.alias || [],
    redirect: designersQNW9hu68acMeta?.redirect,
    component: () => import("/app/pages/dubai/designers.vue").then(m => m.default || m)
  },
  {
    name: designersQNW9hu68acMeta?.name ?? "dubai-designers___ru",
    path: designersQNW9hu68acMeta?.path ?? "/ru/dubai/designers",
    meta: designersQNW9hu68acMeta || {},
    alias: designersQNW9hu68acMeta?.alias || [],
    redirect: designersQNW9hu68acMeta?.redirect,
    component: () => import("/app/pages/dubai/designers.vue").then(m => m.default || m)
  },
  {
    name: designersQNW9hu68acMeta?.name ?? "dubai-designers___fr",
    path: designersQNW9hu68acMeta?.path ?? "/fr/dubai/designers",
    meta: designersQNW9hu68acMeta || {},
    alias: designersQNW9hu68acMeta?.alias || [],
    redirect: designersQNW9hu68acMeta?.redirect,
    component: () => import("/app/pages/dubai/designers.vue").then(m => m.default || m)
  },
  {
    name: designersQNW9hu68acMeta?.name ?? "dubai-designers___ar",
    path: designersQNW9hu68acMeta?.path ?? "/ar/dubai/designers",
    meta: designersQNW9hu68acMeta || {},
    alias: designersQNW9hu68acMeta?.alias || [],
    redirect: designersQNW9hu68acMeta?.redirect,
    component: () => import("/app/pages/dubai/designers.vue").then(m => m.default || m)
  },
  {
    name: realtybrbbQJQAcUMeta?.name ?? "dubai-realty___en",
    path: realtybrbbQJQAcUMeta?.path ?? "/en/dubai/realty",
    meta: realtybrbbQJQAcUMeta || {},
    alias: realtybrbbQJQAcUMeta?.alias || [],
    redirect: realtybrbbQJQAcUMeta?.redirect,
    component: () => import("/app/pages/dubai/realty.vue").then(m => m.default || m)
  },
  {
    name: realtybrbbQJQAcUMeta?.name ?? "dubai-realty___es",
    path: realtybrbbQJQAcUMeta?.path ?? "/es/dubai/realty",
    meta: realtybrbbQJQAcUMeta || {},
    alias: realtybrbbQJQAcUMeta?.alias || [],
    redirect: realtybrbbQJQAcUMeta?.redirect,
    component: () => import("/app/pages/dubai/realty.vue").then(m => m.default || m)
  },
  {
    name: realtybrbbQJQAcUMeta?.name ?? "dubai-realty___ru",
    path: realtybrbbQJQAcUMeta?.path ?? "/ru/dubai/realty",
    meta: realtybrbbQJQAcUMeta || {},
    alias: realtybrbbQJQAcUMeta?.alias || [],
    redirect: realtybrbbQJQAcUMeta?.redirect,
    component: () => import("/app/pages/dubai/realty.vue").then(m => m.default || m)
  },
  {
    name: realtybrbbQJQAcUMeta?.name ?? "dubai-realty___fr",
    path: realtybrbbQJQAcUMeta?.path ?? "/fr/dubai/realty",
    meta: realtybrbbQJQAcUMeta || {},
    alias: realtybrbbQJQAcUMeta?.alias || [],
    redirect: realtybrbbQJQAcUMeta?.redirect,
    component: () => import("/app/pages/dubai/realty.vue").then(m => m.default || m)
  },
  {
    name: realtybrbbQJQAcUMeta?.name ?? "dubai-realty___ar",
    path: realtybrbbQJQAcUMeta?.path ?? "/ar/dubai/realty",
    meta: realtybrbbQJQAcUMeta || {},
    alias: realtybrbbQJQAcUMeta?.alias || [],
    redirect: realtybrbbQJQAcUMeta?.redirect,
    component: () => import("/app/pages/dubai/realty.vue").then(m => m.default || m)
  },
  {
    name: dubai2W7R6ZH480DMeta?.name ?? "dubai2___en",
    path: dubai2W7R6ZH480DMeta?.path ?? "/en/dubai2",
    meta: dubai2W7R6ZH480DMeta || {},
    alias: dubai2W7R6ZH480DMeta?.alias || [],
    redirect: dubai2W7R6ZH480DMeta?.redirect,
    component: () => import("/app/pages/dubai2.vue").then(m => m.default || m)
  },
  {
    name: dubai2W7R6ZH480DMeta?.name ?? "dubai2___es",
    path: dubai2W7R6ZH480DMeta?.path ?? "/es/dubai2",
    meta: dubai2W7R6ZH480DMeta || {},
    alias: dubai2W7R6ZH480DMeta?.alias || [],
    redirect: dubai2W7R6ZH480DMeta?.redirect,
    component: () => import("/app/pages/dubai2.vue").then(m => m.default || m)
  },
  {
    name: dubai2W7R6ZH480DMeta?.name ?? "dubai2___ru",
    path: dubai2W7R6ZH480DMeta?.path ?? "/ru/dubai2",
    meta: dubai2W7R6ZH480DMeta || {},
    alias: dubai2W7R6ZH480DMeta?.alias || [],
    redirect: dubai2W7R6ZH480DMeta?.redirect,
    component: () => import("/app/pages/dubai2.vue").then(m => m.default || m)
  },
  {
    name: dubai2W7R6ZH480DMeta?.name ?? "dubai2___fr",
    path: dubai2W7R6ZH480DMeta?.path ?? "/fr/dubai2",
    meta: dubai2W7R6ZH480DMeta || {},
    alias: dubai2W7R6ZH480DMeta?.alias || [],
    redirect: dubai2W7R6ZH480DMeta?.redirect,
    component: () => import("/app/pages/dubai2.vue").then(m => m.default || m)
  },
  {
    name: dubai2W7R6ZH480DMeta?.name ?? "dubai2___ar",
    path: dubai2W7R6ZH480DMeta?.path ?? "/ar/dubai2",
    meta: dubai2W7R6ZH480DMeta || {},
    alias: dubai2W7R6ZH480DMeta?.alias || [],
    redirect: dubai2W7R6ZH480DMeta?.redirect,
    component: () => import("/app/pages/dubai2.vue").then(m => m.default || m)
  },
  {
    name: for_45designersL9HRWdjc5oMeta?.name ?? "for-designers___en",
    path: for_45designersL9HRWdjc5oMeta?.path ?? "/en/for-designers",
    meta: for_45designersL9HRWdjc5oMeta || {},
    alias: for_45designersL9HRWdjc5oMeta?.alias || [],
    redirect: for_45designersL9HRWdjc5oMeta?.redirect,
    component: () => import("/app/pages/for-designers.vue").then(m => m.default || m)
  },
  {
    name: for_45designersL9HRWdjc5oMeta?.name ?? "for-designers___es",
    path: for_45designersL9HRWdjc5oMeta?.path ?? "/es/for-designers",
    meta: for_45designersL9HRWdjc5oMeta || {},
    alias: for_45designersL9HRWdjc5oMeta?.alias || [],
    redirect: for_45designersL9HRWdjc5oMeta?.redirect,
    component: () => import("/app/pages/for-designers.vue").then(m => m.default || m)
  },
  {
    name: for_45designersL9HRWdjc5oMeta?.name ?? "for-designers___ru",
    path: for_45designersL9HRWdjc5oMeta?.path ?? "/ru/for-designers",
    meta: for_45designersL9HRWdjc5oMeta || {},
    alias: for_45designersL9HRWdjc5oMeta?.alias || [],
    redirect: for_45designersL9HRWdjc5oMeta?.redirect,
    component: () => import("/app/pages/for-designers.vue").then(m => m.default || m)
  },
  {
    name: for_45designersL9HRWdjc5oMeta?.name ?? "for-designers___fr",
    path: for_45designersL9HRWdjc5oMeta?.path ?? "/fr/for-designers",
    meta: for_45designersL9HRWdjc5oMeta || {},
    alias: for_45designersL9HRWdjc5oMeta?.alias || [],
    redirect: for_45designersL9HRWdjc5oMeta?.redirect,
    component: () => import("/app/pages/for-designers.vue").then(m => m.default || m)
  },
  {
    name: for_45designersL9HRWdjc5oMeta?.name ?? "for-designers___ar",
    path: for_45designersL9HRWdjc5oMeta?.path ?? "/ar/for-designers",
    meta: for_45designersL9HRWdjc5oMeta || {},
    alias: for_45designersL9HRWdjc5oMeta?.alias || [],
    redirect: for_45designersL9HRWdjc5oMeta?.redirect,
    component: () => import("/app/pages/for-designers.vue").then(m => m.default || m)
  },
  {
    name: for_45developersM85EQO49RgMeta?.name ?? "for-developers___en",
    path: for_45developersM85EQO49RgMeta?.path ?? "/en/for-developers",
    meta: for_45developersM85EQO49RgMeta || {},
    alias: for_45developersM85EQO49RgMeta?.alias || [],
    redirect: for_45developersM85EQO49RgMeta?.redirect,
    component: () => import("/app/pages/for-developers.vue").then(m => m.default || m)
  },
  {
    name: for_45developersM85EQO49RgMeta?.name ?? "for-developers___es",
    path: for_45developersM85EQO49RgMeta?.path ?? "/es/for-developers",
    meta: for_45developersM85EQO49RgMeta || {},
    alias: for_45developersM85EQO49RgMeta?.alias || [],
    redirect: for_45developersM85EQO49RgMeta?.redirect,
    component: () => import("/app/pages/for-developers.vue").then(m => m.default || m)
  },
  {
    name: for_45developersM85EQO49RgMeta?.name ?? "for-developers___ru",
    path: for_45developersM85EQO49RgMeta?.path ?? "/ru/for-developers",
    meta: for_45developersM85EQO49RgMeta || {},
    alias: for_45developersM85EQO49RgMeta?.alias || [],
    redirect: for_45developersM85EQO49RgMeta?.redirect,
    component: () => import("/app/pages/for-developers.vue").then(m => m.default || m)
  },
  {
    name: for_45developersM85EQO49RgMeta?.name ?? "for-developers___fr",
    path: for_45developersM85EQO49RgMeta?.path ?? "/fr/for-developers",
    meta: for_45developersM85EQO49RgMeta || {},
    alias: for_45developersM85EQO49RgMeta?.alias || [],
    redirect: for_45developersM85EQO49RgMeta?.redirect,
    component: () => import("/app/pages/for-developers.vue").then(m => m.default || m)
  },
  {
    name: for_45developersM85EQO49RgMeta?.name ?? "for-developers___ar",
    path: for_45developersM85EQO49RgMeta?.path ?? "/ar/for-developers",
    meta: for_45developersM85EQO49RgMeta || {},
    alias: for_45developersM85EQO49RgMeta?.alias || [],
    redirect: for_45developersM85EQO49RgMeta?.redirect,
    component: () => import("/app/pages/for-developers.vue").then(m => m.default || m)
  },
  {
    name: for_45finishersOwdClBzx5IMeta?.name ?? "for-finishers___en",
    path: for_45finishersOwdClBzx5IMeta?.path ?? "/en/for-finishers",
    meta: for_45finishersOwdClBzx5IMeta || {},
    alias: for_45finishersOwdClBzx5IMeta?.alias || [],
    redirect: for_45finishersOwdClBzx5IMeta?.redirect,
    component: () => import("/app/pages/for-finishers.vue").then(m => m.default || m)
  },
  {
    name: for_45finishersOwdClBzx5IMeta?.name ?? "for-finishers___es",
    path: for_45finishersOwdClBzx5IMeta?.path ?? "/es/for-finishers",
    meta: for_45finishersOwdClBzx5IMeta || {},
    alias: for_45finishersOwdClBzx5IMeta?.alias || [],
    redirect: for_45finishersOwdClBzx5IMeta?.redirect,
    component: () => import("/app/pages/for-finishers.vue").then(m => m.default || m)
  },
  {
    name: for_45finishersOwdClBzx5IMeta?.name ?? "for-finishers___ru",
    path: for_45finishersOwdClBzx5IMeta?.path ?? "/ru/for-finishers",
    meta: for_45finishersOwdClBzx5IMeta || {},
    alias: for_45finishersOwdClBzx5IMeta?.alias || [],
    redirect: for_45finishersOwdClBzx5IMeta?.redirect,
    component: () => import("/app/pages/for-finishers.vue").then(m => m.default || m)
  },
  {
    name: for_45finishersOwdClBzx5IMeta?.name ?? "for-finishers___fr",
    path: for_45finishersOwdClBzx5IMeta?.path ?? "/fr/for-finishers",
    meta: for_45finishersOwdClBzx5IMeta || {},
    alias: for_45finishersOwdClBzx5IMeta?.alias || [],
    redirect: for_45finishersOwdClBzx5IMeta?.redirect,
    component: () => import("/app/pages/for-finishers.vue").then(m => m.default || m)
  },
  {
    name: for_45finishersOwdClBzx5IMeta?.name ?? "for-finishers___ar",
    path: for_45finishersOwdClBzx5IMeta?.path ?? "/ar/for-finishers",
    meta: for_45finishersOwdClBzx5IMeta || {},
    alias: for_45finishersOwdClBzx5IMeta?.alias || [],
    redirect: for_45finishersOwdClBzx5IMeta?.redirect,
    component: () => import("/app/pages/for-finishers.vue").then(m => m.default || m)
  },
  {
    name: for_45furniture_45makersUihflGw9RnMeta?.name ?? "for-furniture-makers___en",
    path: for_45furniture_45makersUihflGw9RnMeta?.path ?? "/en/for-furniture-makers",
    meta: for_45furniture_45makersUihflGw9RnMeta || {},
    alias: for_45furniture_45makersUihflGw9RnMeta?.alias || [],
    redirect: for_45furniture_45makersUihflGw9RnMeta?.redirect,
    component: () => import("/app/pages/for-furniture-makers.vue").then(m => m.default || m)
  },
  {
    name: for_45furniture_45makersUihflGw9RnMeta?.name ?? "for-furniture-makers___es",
    path: for_45furniture_45makersUihflGw9RnMeta?.path ?? "/es/for-furniture-makers",
    meta: for_45furniture_45makersUihflGw9RnMeta || {},
    alias: for_45furniture_45makersUihflGw9RnMeta?.alias || [],
    redirect: for_45furniture_45makersUihflGw9RnMeta?.redirect,
    component: () => import("/app/pages/for-furniture-makers.vue").then(m => m.default || m)
  },
  {
    name: for_45furniture_45makersUihflGw9RnMeta?.name ?? "for-furniture-makers___ru",
    path: for_45furniture_45makersUihflGw9RnMeta?.path ?? "/ru/for-furniture-makers",
    meta: for_45furniture_45makersUihflGw9RnMeta || {},
    alias: for_45furniture_45makersUihflGw9RnMeta?.alias || [],
    redirect: for_45furniture_45makersUihflGw9RnMeta?.redirect,
    component: () => import("/app/pages/for-furniture-makers.vue").then(m => m.default || m)
  },
  {
    name: for_45furniture_45makersUihflGw9RnMeta?.name ?? "for-furniture-makers___fr",
    path: for_45furniture_45makersUihflGw9RnMeta?.path ?? "/fr/for-furniture-makers",
    meta: for_45furniture_45makersUihflGw9RnMeta || {},
    alias: for_45furniture_45makersUihflGw9RnMeta?.alias || [],
    redirect: for_45furniture_45makersUihflGw9RnMeta?.redirect,
    component: () => import("/app/pages/for-furniture-makers.vue").then(m => m.default || m)
  },
  {
    name: for_45furniture_45makersUihflGw9RnMeta?.name ?? "for-furniture-makers___ar",
    path: for_45furniture_45makersUihflGw9RnMeta?.path ?? "/ar/for-furniture-makers",
    meta: for_45furniture_45makersUihflGw9RnMeta || {},
    alias: for_45furniture_45makersUihflGw9RnMeta?.alias || [],
    redirect: for_45furniture_45makersUihflGw9RnMeta?.redirect,
    component: () => import("/app/pages/for-furniture-makers.vue").then(m => m.default || m)
  },
  {
    name: for_45householdsNX1Wvwxt6cMeta?.name ?? "for-households___en",
    path: for_45householdsNX1Wvwxt6cMeta?.path ?? "/en/for-households",
    meta: for_45householdsNX1Wvwxt6cMeta || {},
    alias: for_45householdsNX1Wvwxt6cMeta?.alias || [],
    redirect: for_45householdsNX1Wvwxt6cMeta?.redirect,
    component: () => import("/app/pages/for-households.vue").then(m => m.default || m)
  },
  {
    name: for_45householdsNX1Wvwxt6cMeta?.name ?? "for-households___es",
    path: for_45householdsNX1Wvwxt6cMeta?.path ?? "/es/for-households",
    meta: for_45householdsNX1Wvwxt6cMeta || {},
    alias: for_45householdsNX1Wvwxt6cMeta?.alias || [],
    redirect: for_45householdsNX1Wvwxt6cMeta?.redirect,
    component: () => import("/app/pages/for-households.vue").then(m => m.default || m)
  },
  {
    name: for_45householdsNX1Wvwxt6cMeta?.name ?? "for-households___ru",
    path: for_45householdsNX1Wvwxt6cMeta?.path ?? "/ru/for-households",
    meta: for_45householdsNX1Wvwxt6cMeta || {},
    alias: for_45householdsNX1Wvwxt6cMeta?.alias || [],
    redirect: for_45householdsNX1Wvwxt6cMeta?.redirect,
    component: () => import("/app/pages/for-households.vue").then(m => m.default || m)
  },
  {
    name: for_45householdsNX1Wvwxt6cMeta?.name ?? "for-households___fr",
    path: for_45householdsNX1Wvwxt6cMeta?.path ?? "/fr/for-households",
    meta: for_45householdsNX1Wvwxt6cMeta || {},
    alias: for_45householdsNX1Wvwxt6cMeta?.alias || [],
    redirect: for_45householdsNX1Wvwxt6cMeta?.redirect,
    component: () => import("/app/pages/for-households.vue").then(m => m.default || m)
  },
  {
    name: for_45householdsNX1Wvwxt6cMeta?.name ?? "for-households___ar",
    path: for_45householdsNX1Wvwxt6cMeta?.path ?? "/ar/for-households",
    meta: for_45householdsNX1Wvwxt6cMeta || {},
    alias: for_45householdsNX1Wvwxt6cMeta?.alias || [],
    redirect: for_45householdsNX1Wvwxt6cMeta?.redirect,
    component: () => import("/app/pages/for-households.vue").then(m => m.default || m)
  },
  {
    name: indexHZTmkntJJcMeta?.name ?? "furniture-companies___en",
    path: indexHZTmkntJJcMeta?.path ?? "/en/furniture-companies",
    meta: indexHZTmkntJJcMeta || {},
    alias: indexHZTmkntJJcMeta?.alias || [],
    redirect: indexHZTmkntJJcMeta?.redirect,
    component: () => import("/app/pages/furniture-companies/index.vue").then(m => m.default || m)
  },
  {
    name: indexHZTmkntJJcMeta?.name ?? "furniture-companies___es",
    path: indexHZTmkntJJcMeta?.path ?? "/es/furniture-companies",
    meta: indexHZTmkntJJcMeta || {},
    alias: indexHZTmkntJJcMeta?.alias || [],
    redirect: indexHZTmkntJJcMeta?.redirect,
    component: () => import("/app/pages/furniture-companies/index.vue").then(m => m.default || m)
  },
  {
    name: indexHZTmkntJJcMeta?.name ?? "furniture-companies___ru",
    path: indexHZTmkntJJcMeta?.path ?? "/ru/furniture-companies",
    meta: indexHZTmkntJJcMeta || {},
    alias: indexHZTmkntJJcMeta?.alias || [],
    redirect: indexHZTmkntJJcMeta?.redirect,
    component: () => import("/app/pages/furniture-companies/index.vue").then(m => m.default || m)
  },
  {
    name: indexHZTmkntJJcMeta?.name ?? "furniture-companies___fr",
    path: indexHZTmkntJJcMeta?.path ?? "/fr/furniture-companies",
    meta: indexHZTmkntJJcMeta || {},
    alias: indexHZTmkntJJcMeta?.alias || [],
    redirect: indexHZTmkntJJcMeta?.redirect,
    component: () => import("/app/pages/furniture-companies/index.vue").then(m => m.default || m)
  },
  {
    name: indexHZTmkntJJcMeta?.name ?? "furniture-companies___ar",
    path: indexHZTmkntJJcMeta?.path ?? "/ar/furniture-companies",
    meta: indexHZTmkntJJcMeta || {},
    alias: indexHZTmkntJJcMeta?.alias || [],
    redirect: indexHZTmkntJJcMeta?.redirect,
    component: () => import("/app/pages/furniture-companies/index.vue").then(m => m.default || m)
  },
  {
    name: galleryvsTGmCPX6gMeta?.name ?? "gallery___en",
    path: galleryvsTGmCPX6gMeta?.path ?? "/en/gallery",
    meta: galleryvsTGmCPX6gMeta || {},
    alias: galleryvsTGmCPX6gMeta?.alias || [],
    redirect: galleryvsTGmCPX6gMeta?.redirect,
    component: () => import("/app/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: galleryvsTGmCPX6gMeta?.name ?? "gallery___es",
    path: galleryvsTGmCPX6gMeta?.path ?? "/es/gallery",
    meta: galleryvsTGmCPX6gMeta || {},
    alias: galleryvsTGmCPX6gMeta?.alias || [],
    redirect: galleryvsTGmCPX6gMeta?.redirect,
    component: () => import("/app/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: galleryvsTGmCPX6gMeta?.name ?? "gallery___ru",
    path: galleryvsTGmCPX6gMeta?.path ?? "/ru/gallery",
    meta: galleryvsTGmCPX6gMeta || {},
    alias: galleryvsTGmCPX6gMeta?.alias || [],
    redirect: galleryvsTGmCPX6gMeta?.redirect,
    component: () => import("/app/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: galleryvsTGmCPX6gMeta?.name ?? "gallery___fr",
    path: galleryvsTGmCPX6gMeta?.path ?? "/fr/gallery",
    meta: galleryvsTGmCPX6gMeta || {},
    alias: galleryvsTGmCPX6gMeta?.alias || [],
    redirect: galleryvsTGmCPX6gMeta?.redirect,
    component: () => import("/app/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: galleryvsTGmCPX6gMeta?.name ?? "gallery___ar",
    path: galleryvsTGmCPX6gMeta?.path ?? "/ar/gallery",
    meta: galleryvsTGmCPX6gMeta || {},
    alias: galleryvsTGmCPX6gMeta?.alias || [],
    redirect: galleryvsTGmCPX6gMeta?.redirect,
    component: () => import("/app/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: getsharedJH75BX2jiuMeta?.name ?? "getshared___en",
    path: getsharedJH75BX2jiuMeta?.path ?? "/en/getshared",
    meta: getsharedJH75BX2jiuMeta || {},
    alias: getsharedJH75BX2jiuMeta?.alias || [],
    redirect: getsharedJH75BX2jiuMeta?.redirect,
    component: () => import("/app/pages/getshared.vue").then(m => m.default || m)
  },
  {
    name: getsharedJH75BX2jiuMeta?.name ?? "getshared___es",
    path: getsharedJH75BX2jiuMeta?.path ?? "/es/getshared",
    meta: getsharedJH75BX2jiuMeta || {},
    alias: getsharedJH75BX2jiuMeta?.alias || [],
    redirect: getsharedJH75BX2jiuMeta?.redirect,
    component: () => import("/app/pages/getshared.vue").then(m => m.default || m)
  },
  {
    name: getsharedJH75BX2jiuMeta?.name ?? "getshared___ru",
    path: getsharedJH75BX2jiuMeta?.path ?? "/ru/getshared",
    meta: getsharedJH75BX2jiuMeta || {},
    alias: getsharedJH75BX2jiuMeta?.alias || [],
    redirect: getsharedJH75BX2jiuMeta?.redirect,
    component: () => import("/app/pages/getshared.vue").then(m => m.default || m)
  },
  {
    name: getsharedJH75BX2jiuMeta?.name ?? "getshared___fr",
    path: getsharedJH75BX2jiuMeta?.path ?? "/fr/getshared",
    meta: getsharedJH75BX2jiuMeta || {},
    alias: getsharedJH75BX2jiuMeta?.alias || [],
    redirect: getsharedJH75BX2jiuMeta?.redirect,
    component: () => import("/app/pages/getshared.vue").then(m => m.default || m)
  },
  {
    name: getsharedJH75BX2jiuMeta?.name ?? "getshared___ar",
    path: getsharedJH75BX2jiuMeta?.path ?? "/ar/getshared",
    meta: getsharedJH75BX2jiuMeta || {},
    alias: getsharedJH75BX2jiuMeta?.alias || [],
    redirect: getsharedJH75BX2jiuMeta?.redirect,
    component: () => import("/app/pages/getshared.vue").then(m => m.default || m)
  },
  {
    name: gitexMoroccocjBvrSaYIUMeta?.name ?? "gitexMorocco___en",
    path: gitexMoroccocjBvrSaYIUMeta?.path ?? "/en/gitexMorocco",
    meta: gitexMoroccocjBvrSaYIUMeta || {},
    alias: gitexMoroccocjBvrSaYIUMeta?.alias || [],
    redirect: gitexMoroccocjBvrSaYIUMeta?.redirect,
    component: () => import("/app/pages/gitexMorocco.vue").then(m => m.default || m)
  },
  {
    name: gitexMoroccocjBvrSaYIUMeta?.name ?? "gitexMorocco___es",
    path: gitexMoroccocjBvrSaYIUMeta?.path ?? "/es/gitexMorocco",
    meta: gitexMoroccocjBvrSaYIUMeta || {},
    alias: gitexMoroccocjBvrSaYIUMeta?.alias || [],
    redirect: gitexMoroccocjBvrSaYIUMeta?.redirect,
    component: () => import("/app/pages/gitexMorocco.vue").then(m => m.default || m)
  },
  {
    name: gitexMoroccocjBvrSaYIUMeta?.name ?? "gitexMorocco___ru",
    path: gitexMoroccocjBvrSaYIUMeta?.path ?? "/ru/gitexMorocco",
    meta: gitexMoroccocjBvrSaYIUMeta || {},
    alias: gitexMoroccocjBvrSaYIUMeta?.alias || [],
    redirect: gitexMoroccocjBvrSaYIUMeta?.redirect,
    component: () => import("/app/pages/gitexMorocco.vue").then(m => m.default || m)
  },
  {
    name: gitexMoroccocjBvrSaYIUMeta?.name ?? "gitexMorocco___fr",
    path: gitexMoroccocjBvrSaYIUMeta?.path ?? "/fr/gitexMorocco",
    meta: gitexMoroccocjBvrSaYIUMeta || {},
    alias: gitexMoroccocjBvrSaYIUMeta?.alias || [],
    redirect: gitexMoroccocjBvrSaYIUMeta?.redirect,
    component: () => import("/app/pages/gitexMorocco.vue").then(m => m.default || m)
  },
  {
    name: gitexMoroccocjBvrSaYIUMeta?.name ?? "gitexMorocco___ar",
    path: gitexMoroccocjBvrSaYIUMeta?.path ?? "/ar/gitexMorocco",
    meta: gitexMoroccocjBvrSaYIUMeta || {},
    alias: gitexMoroccocjBvrSaYIUMeta?.alias || [],
    redirect: gitexMoroccocjBvrSaYIUMeta?.redirect,
    component: () => import("/app/pages/gitexMorocco.vue").then(m => m.default || m)
  },
  {
    name: indexamjyqCQoXrMeta?.name ?? "help-center-about-dream-flat___en",
    path: indexamjyqCQoXrMeta?.path ?? "/en/help-center/about-dream-flat",
    meta: indexamjyqCQoXrMeta || {},
    alias: indexamjyqCQoXrMeta?.alias || [],
    redirect: indexamjyqCQoXrMeta?.redirect,
    component: () => import("/app/pages/help-center/about-dream-flat/index.vue").then(m => m.default || m)
  },
  {
    name: indexamjyqCQoXrMeta?.name ?? "help-center-about-dream-flat___es",
    path: indexamjyqCQoXrMeta?.path ?? "/es/help-center/about-dream-flat",
    meta: indexamjyqCQoXrMeta || {},
    alias: indexamjyqCQoXrMeta?.alias || [],
    redirect: indexamjyqCQoXrMeta?.redirect,
    component: () => import("/app/pages/help-center/about-dream-flat/index.vue").then(m => m.default || m)
  },
  {
    name: indexamjyqCQoXrMeta?.name ?? "help-center-about-dream-flat___ru",
    path: indexamjyqCQoXrMeta?.path ?? "/ru/help-center/about-dream-flat",
    meta: indexamjyqCQoXrMeta || {},
    alias: indexamjyqCQoXrMeta?.alias || [],
    redirect: indexamjyqCQoXrMeta?.redirect,
    component: () => import("/app/pages/help-center/about-dream-flat/index.vue").then(m => m.default || m)
  },
  {
    name: indexamjyqCQoXrMeta?.name ?? "help-center-about-dream-flat___fr",
    path: indexamjyqCQoXrMeta?.path ?? "/fr/help-center/about-dream-flat",
    meta: indexamjyqCQoXrMeta || {},
    alias: indexamjyqCQoXrMeta?.alias || [],
    redirect: indexamjyqCQoXrMeta?.redirect,
    component: () => import("/app/pages/help-center/about-dream-flat/index.vue").then(m => m.default || m)
  },
  {
    name: indexamjyqCQoXrMeta?.name ?? "help-center-about-dream-flat___ar",
    path: indexamjyqCQoXrMeta?.path ?? "/ar/help-center/about-dream-flat",
    meta: indexamjyqCQoXrMeta || {},
    alias: indexamjyqCQoXrMeta?.alias || [],
    redirect: indexamjyqCQoXrMeta?.redirect,
    component: () => import("/app/pages/help-center/about-dream-flat/index.vue").then(m => m.default || m)
  },
  {
    name: indexeHVpFSjFO4Meta?.name ?? "help-center-create-content-and-project-demonstration___en",
    path: indexeHVpFSjFO4Meta?.path ?? "/en/help-center/create-content-and-project-demonstration",
    meta: indexeHVpFSjFO4Meta || {},
    alias: indexeHVpFSjFO4Meta?.alias || [],
    redirect: indexeHVpFSjFO4Meta?.redirect,
    component: () => import("/app/pages/help-center/create-content-and-project-demonstration/index.vue").then(m => m.default || m)
  },
  {
    name: indexeHVpFSjFO4Meta?.name ?? "help-center-create-content-and-project-demonstration___es",
    path: indexeHVpFSjFO4Meta?.path ?? "/es/help-center/create-content-and-project-demonstration",
    meta: indexeHVpFSjFO4Meta || {},
    alias: indexeHVpFSjFO4Meta?.alias || [],
    redirect: indexeHVpFSjFO4Meta?.redirect,
    component: () => import("/app/pages/help-center/create-content-and-project-demonstration/index.vue").then(m => m.default || m)
  },
  {
    name: indexeHVpFSjFO4Meta?.name ?? "help-center-create-content-and-project-demonstration___ru",
    path: indexeHVpFSjFO4Meta?.path ?? "/ru/help-center/create-content-and-project-demonstration",
    meta: indexeHVpFSjFO4Meta || {},
    alias: indexeHVpFSjFO4Meta?.alias || [],
    redirect: indexeHVpFSjFO4Meta?.redirect,
    component: () => import("/app/pages/help-center/create-content-and-project-demonstration/index.vue").then(m => m.default || m)
  },
  {
    name: indexeHVpFSjFO4Meta?.name ?? "help-center-create-content-and-project-demonstration___fr",
    path: indexeHVpFSjFO4Meta?.path ?? "/fr/help-center/create-content-and-project-demonstration",
    meta: indexeHVpFSjFO4Meta || {},
    alias: indexeHVpFSjFO4Meta?.alias || [],
    redirect: indexeHVpFSjFO4Meta?.redirect,
    component: () => import("/app/pages/help-center/create-content-and-project-demonstration/index.vue").then(m => m.default || m)
  },
  {
    name: indexeHVpFSjFO4Meta?.name ?? "help-center-create-content-and-project-demonstration___ar",
    path: indexeHVpFSjFO4Meta?.path ?? "/ar/help-center/create-content-and-project-demonstration",
    meta: indexeHVpFSjFO4Meta || {},
    alias: indexeHVpFSjFO4Meta?.alias || [],
    redirect: indexeHVpFSjFO4Meta?.redirect,
    component: () => import("/app/pages/help-center/create-content-and-project-demonstration/index.vue").then(m => m.default || m)
  },
  {
    name: indexMOeWwviCx6Meta?.name ?? "help-center-creating-project___en",
    path: indexMOeWwviCx6Meta?.path ?? "/en/help-center/creating-project",
    meta: indexMOeWwviCx6Meta || {},
    alias: indexMOeWwviCx6Meta?.alias || [],
    redirect: indexMOeWwviCx6Meta?.redirect,
    component: () => import("/app/pages/help-center/creating-project/index.vue").then(m => m.default || m)
  },
  {
    name: indexMOeWwviCx6Meta?.name ?? "help-center-creating-project___es",
    path: indexMOeWwviCx6Meta?.path ?? "/es/help-center/creating-project",
    meta: indexMOeWwviCx6Meta || {},
    alias: indexMOeWwviCx6Meta?.alias || [],
    redirect: indexMOeWwviCx6Meta?.redirect,
    component: () => import("/app/pages/help-center/creating-project/index.vue").then(m => m.default || m)
  },
  {
    name: indexMOeWwviCx6Meta?.name ?? "help-center-creating-project___ru",
    path: indexMOeWwviCx6Meta?.path ?? "/ru/help-center/creating-project",
    meta: indexMOeWwviCx6Meta || {},
    alias: indexMOeWwviCx6Meta?.alias || [],
    redirect: indexMOeWwviCx6Meta?.redirect,
    component: () => import("/app/pages/help-center/creating-project/index.vue").then(m => m.default || m)
  },
  {
    name: indexMOeWwviCx6Meta?.name ?? "help-center-creating-project___fr",
    path: indexMOeWwviCx6Meta?.path ?? "/fr/help-center/creating-project",
    meta: indexMOeWwviCx6Meta || {},
    alias: indexMOeWwviCx6Meta?.alias || [],
    redirect: indexMOeWwviCx6Meta?.redirect,
    component: () => import("/app/pages/help-center/creating-project/index.vue").then(m => m.default || m)
  },
  {
    name: indexMOeWwviCx6Meta?.name ?? "help-center-creating-project___ar",
    path: indexMOeWwviCx6Meta?.path ?? "/ar/help-center/creating-project",
    meta: indexMOeWwviCx6Meta || {},
    alias: indexMOeWwviCx6Meta?.alias || [],
    redirect: indexMOeWwviCx6Meta?.redirect,
    component: () => import("/app/pages/help-center/creating-project/index.vue").then(m => m.default || m)
  },
  {
    name: indexNgHTocZnJHMeta?.name ?? "help-center___en",
    path: indexNgHTocZnJHMeta?.path ?? "/en/help-center",
    meta: indexNgHTocZnJHMeta || {},
    alias: indexNgHTocZnJHMeta?.alias || [],
    redirect: indexNgHTocZnJHMeta?.redirect,
    component: () => import("/app/pages/help-center/index.vue").then(m => m.default || m)
  },
  {
    name: indexNgHTocZnJHMeta?.name ?? "help-center___es",
    path: indexNgHTocZnJHMeta?.path ?? "/es/help-center",
    meta: indexNgHTocZnJHMeta || {},
    alias: indexNgHTocZnJHMeta?.alias || [],
    redirect: indexNgHTocZnJHMeta?.redirect,
    component: () => import("/app/pages/help-center/index.vue").then(m => m.default || m)
  },
  {
    name: indexNgHTocZnJHMeta?.name ?? "help-center___ru",
    path: indexNgHTocZnJHMeta?.path ?? "/ru/help-center",
    meta: indexNgHTocZnJHMeta || {},
    alias: indexNgHTocZnJHMeta?.alias || [],
    redirect: indexNgHTocZnJHMeta?.redirect,
    component: () => import("/app/pages/help-center/index.vue").then(m => m.default || m)
  },
  {
    name: indexNgHTocZnJHMeta?.name ?? "help-center___fr",
    path: indexNgHTocZnJHMeta?.path ?? "/fr/help-center",
    meta: indexNgHTocZnJHMeta || {},
    alias: indexNgHTocZnJHMeta?.alias || [],
    redirect: indexNgHTocZnJHMeta?.redirect,
    component: () => import("/app/pages/help-center/index.vue").then(m => m.default || m)
  },
  {
    name: indexNgHTocZnJHMeta?.name ?? "help-center___ar",
    path: indexNgHTocZnJHMeta?.path ?? "/ar/help-center",
    meta: indexNgHTocZnJHMeta || {},
    alias: indexNgHTocZnJHMeta?.alias || [],
    redirect: indexNgHTocZnJHMeta?.redirect,
    component: () => import("/app/pages/help-center/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___en",
    path: indexN6pT4Un8hYMeta?.path ?? "/en/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___es",
    path: indexN6pT4Un8hYMeta?.path ?? "/es/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___ru",
    path: indexN6pT4Un8hYMeta?.path ?? "/ru/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___fr",
    path: indexN6pT4Un8hYMeta?.path ?? "/fr/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___ar",
    path: indexN6pT4Un8hYMeta?.path ?? "/ar/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: launcherlogin622USJAlVYMeta?.name ?? "launcherlogin___en",
    path: launcherlogin622USJAlVYMeta?.path ?? "/en/launcherlogin",
    meta: launcherlogin622USJAlVYMeta || {},
    alias: launcherlogin622USJAlVYMeta?.alias || [],
    redirect: launcherlogin622USJAlVYMeta?.redirect,
    component: () => import("/app/pages/launcherlogin.vue").then(m => m.default || m)
  },
  {
    name: launcherlogin622USJAlVYMeta?.name ?? "launcherlogin___es",
    path: launcherlogin622USJAlVYMeta?.path ?? "/es/launcherlogin",
    meta: launcherlogin622USJAlVYMeta || {},
    alias: launcherlogin622USJAlVYMeta?.alias || [],
    redirect: launcherlogin622USJAlVYMeta?.redirect,
    component: () => import("/app/pages/launcherlogin.vue").then(m => m.default || m)
  },
  {
    name: launcherlogin622USJAlVYMeta?.name ?? "launcherlogin___ru",
    path: launcherlogin622USJAlVYMeta?.path ?? "/ru/launcherlogin",
    meta: launcherlogin622USJAlVYMeta || {},
    alias: launcherlogin622USJAlVYMeta?.alias || [],
    redirect: launcherlogin622USJAlVYMeta?.redirect,
    component: () => import("/app/pages/launcherlogin.vue").then(m => m.default || m)
  },
  {
    name: launcherlogin622USJAlVYMeta?.name ?? "launcherlogin___fr",
    path: launcherlogin622USJAlVYMeta?.path ?? "/fr/launcherlogin",
    meta: launcherlogin622USJAlVYMeta || {},
    alias: launcherlogin622USJAlVYMeta?.alias || [],
    redirect: launcherlogin622USJAlVYMeta?.redirect,
    component: () => import("/app/pages/launcherlogin.vue").then(m => m.default || m)
  },
  {
    name: launcherlogin622USJAlVYMeta?.name ?? "launcherlogin___ar",
    path: launcherlogin622USJAlVYMeta?.path ?? "/ar/launcherlogin",
    meta: launcherlogin622USJAlVYMeta || {},
    alias: launcherlogin622USJAlVYMeta?.alias || [],
    redirect: launcherlogin622USJAlVYMeta?.redirect,
    component: () => import("/app/pages/launcherlogin.vue").then(m => m.default || m)
  },
  {
    name: license1oPxwXaJfhMeta?.name ?? "license___en",
    path: license1oPxwXaJfhMeta?.path ?? "/en/license",
    meta: license1oPxwXaJfhMeta || {},
    alias: license1oPxwXaJfhMeta?.alias || [],
    redirect: license1oPxwXaJfhMeta?.redirect,
    component: () => import("/app/pages/license.vue").then(m => m.default || m)
  },
  {
    name: license1oPxwXaJfhMeta?.name ?? "license___es",
    path: license1oPxwXaJfhMeta?.path ?? "/es/license",
    meta: license1oPxwXaJfhMeta || {},
    alias: license1oPxwXaJfhMeta?.alias || [],
    redirect: license1oPxwXaJfhMeta?.redirect,
    component: () => import("/app/pages/license.vue").then(m => m.default || m)
  },
  {
    name: license1oPxwXaJfhMeta?.name ?? "license___ru",
    path: license1oPxwXaJfhMeta?.path ?? "/ru/license",
    meta: license1oPxwXaJfhMeta || {},
    alias: license1oPxwXaJfhMeta?.alias || [],
    redirect: license1oPxwXaJfhMeta?.redirect,
    component: () => import("/app/pages/license.vue").then(m => m.default || m)
  },
  {
    name: license1oPxwXaJfhMeta?.name ?? "license___fr",
    path: license1oPxwXaJfhMeta?.path ?? "/fr/license",
    meta: license1oPxwXaJfhMeta || {},
    alias: license1oPxwXaJfhMeta?.alias || [],
    redirect: license1oPxwXaJfhMeta?.redirect,
    component: () => import("/app/pages/license.vue").then(m => m.default || m)
  },
  {
    name: license1oPxwXaJfhMeta?.name ?? "license___ar",
    path: license1oPxwXaJfhMeta?.path ?? "/ar/license",
    meta: license1oPxwXaJfhMeta || {},
    alias: license1oPxwXaJfhMeta?.alias || [],
    redirect: license1oPxwXaJfhMeta?.redirect,
    component: () => import("/app/pages/license.vue").then(m => m.default || m)
  },
  {
    name: new8XRwwjkl9dMeta?.name ?? "new___en",
    path: new8XRwwjkl9dMeta?.path ?? "/en/new",
    meta: new8XRwwjkl9dMeta || {},
    alias: new8XRwwjkl9dMeta?.alias || [],
    redirect: new8XRwwjkl9dMeta?.redirect,
    component: () => import("/app/pages/new.vue").then(m => m.default || m)
  },
  {
    name: new8XRwwjkl9dMeta?.name ?? "new___es",
    path: new8XRwwjkl9dMeta?.path ?? "/es/new",
    meta: new8XRwwjkl9dMeta || {},
    alias: new8XRwwjkl9dMeta?.alias || [],
    redirect: new8XRwwjkl9dMeta?.redirect,
    component: () => import("/app/pages/new.vue").then(m => m.default || m)
  },
  {
    name: new8XRwwjkl9dMeta?.name ?? "new___ru",
    path: new8XRwwjkl9dMeta?.path ?? "/ru/new",
    meta: new8XRwwjkl9dMeta || {},
    alias: new8XRwwjkl9dMeta?.alias || [],
    redirect: new8XRwwjkl9dMeta?.redirect,
    component: () => import("/app/pages/new.vue").then(m => m.default || m)
  },
  {
    name: new8XRwwjkl9dMeta?.name ?? "new___fr",
    path: new8XRwwjkl9dMeta?.path ?? "/fr/new",
    meta: new8XRwwjkl9dMeta || {},
    alias: new8XRwwjkl9dMeta?.alias || [],
    redirect: new8XRwwjkl9dMeta?.redirect,
    component: () => import("/app/pages/new.vue").then(m => m.default || m)
  },
  {
    name: new8XRwwjkl9dMeta?.name ?? "new___ar",
    path: new8XRwwjkl9dMeta?.path ?? "/ar/new",
    meta: new8XRwwjkl9dMeta || {},
    alias: new8XRwwjkl9dMeta?.alias || [],
    redirect: new8XRwwjkl9dMeta?.redirect,
    component: () => import("/app/pages/new.vue").then(m => m.default || m)
  },
  {
    name: indexTtCmtGQ9NRMeta?.name ?? "offer___en",
    path: indexTtCmtGQ9NRMeta?.path ?? "/en/offer",
    meta: indexTtCmtGQ9NRMeta || {},
    alias: indexTtCmtGQ9NRMeta?.alias || [],
    redirect: indexTtCmtGQ9NRMeta?.redirect,
    component: () => import("/app/pages/offer/index.vue").then(m => m.default || m)
  },
  {
    name: indexTtCmtGQ9NRMeta?.name ?? "offer___es",
    path: indexTtCmtGQ9NRMeta?.path ?? "/es/offer",
    meta: indexTtCmtGQ9NRMeta || {},
    alias: indexTtCmtGQ9NRMeta?.alias || [],
    redirect: indexTtCmtGQ9NRMeta?.redirect,
    component: () => import("/app/pages/offer/index.vue").then(m => m.default || m)
  },
  {
    name: indexTtCmtGQ9NRMeta?.name ?? "offer___ru",
    path: indexTtCmtGQ9NRMeta?.path ?? "/ru/offer",
    meta: indexTtCmtGQ9NRMeta || {},
    alias: indexTtCmtGQ9NRMeta?.alias || [],
    redirect: indexTtCmtGQ9NRMeta?.redirect,
    component: () => import("/app/pages/offer/index.vue").then(m => m.default || m)
  },
  {
    name: indexTtCmtGQ9NRMeta?.name ?? "offer___fr",
    path: indexTtCmtGQ9NRMeta?.path ?? "/fr/offer",
    meta: indexTtCmtGQ9NRMeta || {},
    alias: indexTtCmtGQ9NRMeta?.alias || [],
    redirect: indexTtCmtGQ9NRMeta?.redirect,
    component: () => import("/app/pages/offer/index.vue").then(m => m.default || m)
  },
  {
    name: indexTtCmtGQ9NRMeta?.name ?? "offer___ar",
    path: indexTtCmtGQ9NRMeta?.path ?? "/ar/offer",
    meta: indexTtCmtGQ9NRMeta || {},
    alias: indexTtCmtGQ9NRMeta?.alias || [],
    redirect: indexTtCmtGQ9NRMeta?.redirect,
    component: () => import("/app/pages/offer/index.vue").then(m => m.default || m)
  },
  {
    name: indextoyI8HEumHMeta?.name ?? "offer-pay-status___en",
    path: indextoyI8HEumHMeta?.path ?? "/en/offer/pay-status",
    meta: indextoyI8HEumHMeta || {},
    alias: indextoyI8HEumHMeta?.alias || [],
    redirect: indextoyI8HEumHMeta?.redirect,
    component: () => import("/app/pages/offer/pay-status/index.vue").then(m => m.default || m)
  },
  {
    name: indextoyI8HEumHMeta?.name ?? "offer-pay-status___es",
    path: indextoyI8HEumHMeta?.path ?? "/es/offer/pay-status",
    meta: indextoyI8HEumHMeta || {},
    alias: indextoyI8HEumHMeta?.alias || [],
    redirect: indextoyI8HEumHMeta?.redirect,
    component: () => import("/app/pages/offer/pay-status/index.vue").then(m => m.default || m)
  },
  {
    name: indextoyI8HEumHMeta?.name ?? "offer-pay-status___ru",
    path: indextoyI8HEumHMeta?.path ?? "/ru/offer/pay-status",
    meta: indextoyI8HEumHMeta || {},
    alias: indextoyI8HEumHMeta?.alias || [],
    redirect: indextoyI8HEumHMeta?.redirect,
    component: () => import("/app/pages/offer/pay-status/index.vue").then(m => m.default || m)
  },
  {
    name: indextoyI8HEumHMeta?.name ?? "offer-pay-status___fr",
    path: indextoyI8HEumHMeta?.path ?? "/fr/offer/pay-status",
    meta: indextoyI8HEumHMeta || {},
    alias: indextoyI8HEumHMeta?.alias || [],
    redirect: indextoyI8HEumHMeta?.redirect,
    component: () => import("/app/pages/offer/pay-status/index.vue").then(m => m.default || m)
  },
  {
    name: indextoyI8HEumHMeta?.name ?? "offer-pay-status___ar",
    path: indextoyI8HEumHMeta?.path ?? "/ar/offer/pay-status",
    meta: indextoyI8HEumHMeta || {},
    alias: indextoyI8HEumHMeta?.alias || [],
    redirect: indextoyI8HEumHMeta?.redirect,
    component: () => import("/app/pages/offer/pay-status/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93yuH5OfQadmMeta?.name ?? "offer-pay-id___en",
    path: _91id_93yuH5OfQadmMeta?.path ?? "/en/offer/pay/:id()",
    meta: _91id_93yuH5OfQadmMeta || {},
    alias: _91id_93yuH5OfQadmMeta?.alias || [],
    redirect: _91id_93yuH5OfQadmMeta?.redirect,
    component: () => import("/app/pages/offer/pay/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93yuH5OfQadmMeta?.name ?? "offer-pay-id___es",
    path: _91id_93yuH5OfQadmMeta?.path ?? "/es/offer/pay/:id()",
    meta: _91id_93yuH5OfQadmMeta || {},
    alias: _91id_93yuH5OfQadmMeta?.alias || [],
    redirect: _91id_93yuH5OfQadmMeta?.redirect,
    component: () => import("/app/pages/offer/pay/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93yuH5OfQadmMeta?.name ?? "offer-pay-id___ru",
    path: _91id_93yuH5OfQadmMeta?.path ?? "/ru/offer/pay/:id()",
    meta: _91id_93yuH5OfQadmMeta || {},
    alias: _91id_93yuH5OfQadmMeta?.alias || [],
    redirect: _91id_93yuH5OfQadmMeta?.redirect,
    component: () => import("/app/pages/offer/pay/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93yuH5OfQadmMeta?.name ?? "offer-pay-id___fr",
    path: _91id_93yuH5OfQadmMeta?.path ?? "/fr/offer/pay/:id()",
    meta: _91id_93yuH5OfQadmMeta || {},
    alias: _91id_93yuH5OfQadmMeta?.alias || [],
    redirect: _91id_93yuH5OfQadmMeta?.redirect,
    component: () => import("/app/pages/offer/pay/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93yuH5OfQadmMeta?.name ?? "offer-pay-id___ar",
    path: _91id_93yuH5OfQadmMeta?.path ?? "/ar/offer/pay/:id()",
    meta: _91id_93yuH5OfQadmMeta || {},
    alias: _91id_93yuH5OfQadmMeta?.alias || [],
    redirect: _91id_93yuH5OfQadmMeta?.redirect,
    component: () => import("/app/pages/offer/pay/[id].vue").then(m => m.default || m)
  },
  {
    name: indexpIDCdxr2vsMeta?.name ?? "offer-plans___en",
    path: indexpIDCdxr2vsMeta?.path ?? "/en/offer/plans",
    meta: indexpIDCdxr2vsMeta || {},
    alias: indexpIDCdxr2vsMeta?.alias || [],
    redirect: indexpIDCdxr2vsMeta?.redirect,
    component: () => import("/app/pages/offer/plans/index.vue").then(m => m.default || m)
  },
  {
    name: indexpIDCdxr2vsMeta?.name ?? "offer-plans___es",
    path: indexpIDCdxr2vsMeta?.path ?? "/es/offer/plans",
    meta: indexpIDCdxr2vsMeta || {},
    alias: indexpIDCdxr2vsMeta?.alias || [],
    redirect: indexpIDCdxr2vsMeta?.redirect,
    component: () => import("/app/pages/offer/plans/index.vue").then(m => m.default || m)
  },
  {
    name: indexpIDCdxr2vsMeta?.name ?? "offer-plans___ru",
    path: indexpIDCdxr2vsMeta?.path ?? "/ru/offer/plans",
    meta: indexpIDCdxr2vsMeta || {},
    alias: indexpIDCdxr2vsMeta?.alias || [],
    redirect: indexpIDCdxr2vsMeta?.redirect,
    component: () => import("/app/pages/offer/plans/index.vue").then(m => m.default || m)
  },
  {
    name: indexpIDCdxr2vsMeta?.name ?? "offer-plans___fr",
    path: indexpIDCdxr2vsMeta?.path ?? "/fr/offer/plans",
    meta: indexpIDCdxr2vsMeta || {},
    alias: indexpIDCdxr2vsMeta?.alias || [],
    redirect: indexpIDCdxr2vsMeta?.redirect,
    component: () => import("/app/pages/offer/plans/index.vue").then(m => m.default || m)
  },
  {
    name: indexpIDCdxr2vsMeta?.name ?? "offer-plans___ar",
    path: indexpIDCdxr2vsMeta?.path ?? "/ar/offer/plans",
    meta: indexpIDCdxr2vsMeta || {},
    alias: indexpIDCdxr2vsMeta?.alias || [],
    redirect: indexpIDCdxr2vsMeta?.redirect,
    component: () => import("/app/pages/offer/plans/index.vue").then(m => m.default || m)
  },
  {
    name: offer2TwTpaUgg1SMeta?.name ?? "offer2___en",
    path: offer2TwTpaUgg1SMeta?.path ?? "/en/offer2",
    meta: offer2TwTpaUgg1SMeta || {},
    alias: offer2TwTpaUgg1SMeta?.alias || [],
    redirect: offer2TwTpaUgg1SMeta?.redirect,
    component: () => import("/app/pages/offer2.vue").then(m => m.default || m)
  },
  {
    name: offer2TwTpaUgg1SMeta?.name ?? "offer2___es",
    path: offer2TwTpaUgg1SMeta?.path ?? "/es/offer2",
    meta: offer2TwTpaUgg1SMeta || {},
    alias: offer2TwTpaUgg1SMeta?.alias || [],
    redirect: offer2TwTpaUgg1SMeta?.redirect,
    component: () => import("/app/pages/offer2.vue").then(m => m.default || m)
  },
  {
    name: offer2TwTpaUgg1SMeta?.name ?? "offer2___ru",
    path: offer2TwTpaUgg1SMeta?.path ?? "/ru/offer2",
    meta: offer2TwTpaUgg1SMeta || {},
    alias: offer2TwTpaUgg1SMeta?.alias || [],
    redirect: offer2TwTpaUgg1SMeta?.redirect,
    component: () => import("/app/pages/offer2.vue").then(m => m.default || m)
  },
  {
    name: offer2TwTpaUgg1SMeta?.name ?? "offer2___fr",
    path: offer2TwTpaUgg1SMeta?.path ?? "/fr/offer2",
    meta: offer2TwTpaUgg1SMeta || {},
    alias: offer2TwTpaUgg1SMeta?.alias || [],
    redirect: offer2TwTpaUgg1SMeta?.redirect,
    component: () => import("/app/pages/offer2.vue").then(m => m.default || m)
  },
  {
    name: offer2TwTpaUgg1SMeta?.name ?? "offer2___ar",
    path: offer2TwTpaUgg1SMeta?.path ?? "/ar/offer2",
    meta: offer2TwTpaUgg1SMeta || {},
    alias: offer2TwTpaUgg1SMeta?.alias || [],
    redirect: offer2TwTpaUgg1SMeta?.redirect,
    component: () => import("/app/pages/offer2.vue").then(m => m.default || m)
  },
  {
    name: pay_45status0cljlImIloMeta?.name ?? "pay-status___en",
    path: pay_45status0cljlImIloMeta?.path ?? "/en/pay-status",
    meta: pay_45status0cljlImIloMeta || {},
    alias: pay_45status0cljlImIloMeta?.alias || [],
    redirect: pay_45status0cljlImIloMeta?.redirect,
    component: () => import("/app/pages/pay-status.vue").then(m => m.default || m)
  },
  {
    name: pay_45status0cljlImIloMeta?.name ?? "pay-status___es",
    path: pay_45status0cljlImIloMeta?.path ?? "/es/pay-status",
    meta: pay_45status0cljlImIloMeta || {},
    alias: pay_45status0cljlImIloMeta?.alias || [],
    redirect: pay_45status0cljlImIloMeta?.redirect,
    component: () => import("/app/pages/pay-status.vue").then(m => m.default || m)
  },
  {
    name: pay_45status0cljlImIloMeta?.name ?? "pay-status___ru",
    path: pay_45status0cljlImIloMeta?.path ?? "/ru/pay-status",
    meta: pay_45status0cljlImIloMeta || {},
    alias: pay_45status0cljlImIloMeta?.alias || [],
    redirect: pay_45status0cljlImIloMeta?.redirect,
    component: () => import("/app/pages/pay-status.vue").then(m => m.default || m)
  },
  {
    name: pay_45status0cljlImIloMeta?.name ?? "pay-status___fr",
    path: pay_45status0cljlImIloMeta?.path ?? "/fr/pay-status",
    meta: pay_45status0cljlImIloMeta || {},
    alias: pay_45status0cljlImIloMeta?.alias || [],
    redirect: pay_45status0cljlImIloMeta?.redirect,
    component: () => import("/app/pages/pay-status.vue").then(m => m.default || m)
  },
  {
    name: pay_45status0cljlImIloMeta?.name ?? "pay-status___ar",
    path: pay_45status0cljlImIloMeta?.path ?? "/ar/pay-status",
    meta: pay_45status0cljlImIloMeta || {},
    alias: pay_45status0cljlImIloMeta?.alias || [],
    redirect: pay_45status0cljlImIloMeta?.redirect,
    component: () => import("/app/pages/pay-status.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Z8sm1odpbeMeta?.name ?? "pay-id___en",
    path: _91id_93Z8sm1odpbeMeta?.path ?? "/en/pay/:id()",
    meta: _91id_93Z8sm1odpbeMeta || {},
    alias: _91id_93Z8sm1odpbeMeta?.alias || [],
    redirect: _91id_93Z8sm1odpbeMeta?.redirect,
    component: () => import("/app/pages/pay/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Z8sm1odpbeMeta?.name ?? "pay-id___es",
    path: _91id_93Z8sm1odpbeMeta?.path ?? "/es/pay/:id()",
    meta: _91id_93Z8sm1odpbeMeta || {},
    alias: _91id_93Z8sm1odpbeMeta?.alias || [],
    redirect: _91id_93Z8sm1odpbeMeta?.redirect,
    component: () => import("/app/pages/pay/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Z8sm1odpbeMeta?.name ?? "pay-id___ru",
    path: _91id_93Z8sm1odpbeMeta?.path ?? "/ru/pay/:id()",
    meta: _91id_93Z8sm1odpbeMeta || {},
    alias: _91id_93Z8sm1odpbeMeta?.alias || [],
    redirect: _91id_93Z8sm1odpbeMeta?.redirect,
    component: () => import("/app/pages/pay/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Z8sm1odpbeMeta?.name ?? "pay-id___fr",
    path: _91id_93Z8sm1odpbeMeta?.path ?? "/fr/pay/:id()",
    meta: _91id_93Z8sm1odpbeMeta || {},
    alias: _91id_93Z8sm1odpbeMeta?.alias || [],
    redirect: _91id_93Z8sm1odpbeMeta?.redirect,
    component: () => import("/app/pages/pay/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Z8sm1odpbeMeta?.name ?? "pay-id___ar",
    path: _91id_93Z8sm1odpbeMeta?.path ?? "/ar/pay/:id()",
    meta: _91id_93Z8sm1odpbeMeta || {},
    alias: _91id_93Z8sm1odpbeMeta?.alias || [],
    redirect: _91id_93Z8sm1odpbeMeta?.redirect,
    component: () => import("/app/pages/pay/[id].vue").then(m => m.default || m)
  },
  {
    name: plansTUY73vr0OjMeta?.name ?? "plans___en",
    path: plansTUY73vr0OjMeta?.path ?? "/en/plans",
    meta: plansTUY73vr0OjMeta || {},
    alias: plansTUY73vr0OjMeta?.alias || [],
    redirect: plansTUY73vr0OjMeta?.redirect,
    component: () => import("/app/pages/plans.vue").then(m => m.default || m)
  },
  {
    name: plansTUY73vr0OjMeta?.name ?? "plans___es",
    path: plansTUY73vr0OjMeta?.path ?? "/es/plans",
    meta: plansTUY73vr0OjMeta || {},
    alias: plansTUY73vr0OjMeta?.alias || [],
    redirect: plansTUY73vr0OjMeta?.redirect,
    component: () => import("/app/pages/plans.vue").then(m => m.default || m)
  },
  {
    name: plansTUY73vr0OjMeta?.name ?? "plans___ru",
    path: plansTUY73vr0OjMeta?.path ?? "/ru/plans",
    meta: plansTUY73vr0OjMeta || {},
    alias: plansTUY73vr0OjMeta?.alias || [],
    redirect: plansTUY73vr0OjMeta?.redirect,
    component: () => import("/app/pages/plans.vue").then(m => m.default || m)
  },
  {
    name: plansTUY73vr0OjMeta?.name ?? "plans___fr",
    path: plansTUY73vr0OjMeta?.path ?? "/fr/plans",
    meta: plansTUY73vr0OjMeta || {},
    alias: plansTUY73vr0OjMeta?.alias || [],
    redirect: plansTUY73vr0OjMeta?.redirect,
    component: () => import("/app/pages/plans.vue").then(m => m.default || m)
  },
  {
    name: plansTUY73vr0OjMeta?.name ?? "plans___ar",
    path: plansTUY73vr0OjMeta?.path ?? "/ar/plans",
    meta: plansTUY73vr0OjMeta || {},
    alias: plansTUY73vr0OjMeta?.alias || [],
    redirect: plansTUY73vr0OjMeta?.redirect,
    component: () => import("/app/pages/plans.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyOPxqa3IsSYMeta?.name ?? "privacy-policy___en",
    path: privacy_45policyOPxqa3IsSYMeta?.path ?? "/en/privacy-policy",
    meta: privacy_45policyOPxqa3IsSYMeta || {},
    alias: privacy_45policyOPxqa3IsSYMeta?.alias || [],
    redirect: privacy_45policyOPxqa3IsSYMeta?.redirect,
    component: () => import("/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyOPxqa3IsSYMeta?.name ?? "privacy-policy___es",
    path: privacy_45policyOPxqa3IsSYMeta?.path ?? "/es/privacy-policy",
    meta: privacy_45policyOPxqa3IsSYMeta || {},
    alias: privacy_45policyOPxqa3IsSYMeta?.alias || [],
    redirect: privacy_45policyOPxqa3IsSYMeta?.redirect,
    component: () => import("/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyOPxqa3IsSYMeta?.name ?? "privacy-policy___ru",
    path: privacy_45policyOPxqa3IsSYMeta?.path ?? "/ru/privacy-policy",
    meta: privacy_45policyOPxqa3IsSYMeta || {},
    alias: privacy_45policyOPxqa3IsSYMeta?.alias || [],
    redirect: privacy_45policyOPxqa3IsSYMeta?.redirect,
    component: () => import("/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyOPxqa3IsSYMeta?.name ?? "privacy-policy___fr",
    path: privacy_45policyOPxqa3IsSYMeta?.path ?? "/fr/privacy-policy",
    meta: privacy_45policyOPxqa3IsSYMeta || {},
    alias: privacy_45policyOPxqa3IsSYMeta?.alias || [],
    redirect: privacy_45policyOPxqa3IsSYMeta?.redirect,
    component: () => import("/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyOPxqa3IsSYMeta?.name ?? "privacy-policy___ar",
    path: privacy_45policyOPxqa3IsSYMeta?.path ?? "/ar/privacy-policy",
    meta: privacy_45policyOPxqa3IsSYMeta || {},
    alias: privacy_45policyOPxqa3IsSYMeta?.alias || [],
    redirect: privacy_45policyOPxqa3IsSYMeta?.redirect,
    component: () => import("/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: profileupBlgdy3vLMeta?.name ?? "profile___en",
    path: profileupBlgdy3vLMeta?.path ?? "/en/profile",
    meta: profileupBlgdy3vLMeta || {},
    alias: profileupBlgdy3vLMeta?.alias || [],
    redirect: profileupBlgdy3vLMeta?.redirect,
    component: () => import("/app/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: verify_passwordgLQvYSM7fBMeta?.name ?? "profile-verify_password",
    path: verify_passwordgLQvYSM7fBMeta?.path ?? "verify_password",
    meta: verify_passwordgLQvYSM7fBMeta || {},
    alias: verify_passwordgLQvYSM7fBMeta?.alias || [],
    redirect: verify_passwordgLQvYSM7fBMeta?.redirect,
    component: () => import("/app/pages/profile/verify_password.vue").then(m => m.default || m)
  },
  {
    name: verify_signupoFYdrbpbWiMeta?.name ?? "profile-verify_signup",
    path: verify_signupoFYdrbpbWiMeta?.path ?? "verify_signup",
    meta: verify_signupoFYdrbpbWiMeta || {},
    alias: verify_signupoFYdrbpbWiMeta?.alias || [],
    redirect: verify_signupoFYdrbpbWiMeta?.redirect,
    component: () => import("/app/pages/profile/verify_signup.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profileupBlgdy3vLMeta?.name ?? "profile___es",
    path: profileupBlgdy3vLMeta?.path ?? "/es/profile",
    meta: profileupBlgdy3vLMeta || {},
    alias: profileupBlgdy3vLMeta?.alias || [],
    redirect: profileupBlgdy3vLMeta?.redirect,
    component: () => import("/app/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: verify_passwordgLQvYSM7fBMeta?.name ?? "profile-verify_password",
    path: verify_passwordgLQvYSM7fBMeta?.path ?? "verify_password",
    meta: verify_passwordgLQvYSM7fBMeta || {},
    alias: verify_passwordgLQvYSM7fBMeta?.alias || [],
    redirect: verify_passwordgLQvYSM7fBMeta?.redirect,
    component: () => import("/app/pages/profile/verify_password.vue").then(m => m.default || m)
  },
  {
    name: verify_signupoFYdrbpbWiMeta?.name ?? "profile-verify_signup",
    path: verify_signupoFYdrbpbWiMeta?.path ?? "verify_signup",
    meta: verify_signupoFYdrbpbWiMeta || {},
    alias: verify_signupoFYdrbpbWiMeta?.alias || [],
    redirect: verify_signupoFYdrbpbWiMeta?.redirect,
    component: () => import("/app/pages/profile/verify_signup.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profileupBlgdy3vLMeta?.name ?? "profile___ru",
    path: profileupBlgdy3vLMeta?.path ?? "/ru/profile",
    meta: profileupBlgdy3vLMeta || {},
    alias: profileupBlgdy3vLMeta?.alias || [],
    redirect: profileupBlgdy3vLMeta?.redirect,
    component: () => import("/app/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: verify_passwordgLQvYSM7fBMeta?.name ?? "profile-verify_password",
    path: verify_passwordgLQvYSM7fBMeta?.path ?? "verify_password",
    meta: verify_passwordgLQvYSM7fBMeta || {},
    alias: verify_passwordgLQvYSM7fBMeta?.alias || [],
    redirect: verify_passwordgLQvYSM7fBMeta?.redirect,
    component: () => import("/app/pages/profile/verify_password.vue").then(m => m.default || m)
  },
  {
    name: verify_signupoFYdrbpbWiMeta?.name ?? "profile-verify_signup",
    path: verify_signupoFYdrbpbWiMeta?.path ?? "verify_signup",
    meta: verify_signupoFYdrbpbWiMeta || {},
    alias: verify_signupoFYdrbpbWiMeta?.alias || [],
    redirect: verify_signupoFYdrbpbWiMeta?.redirect,
    component: () => import("/app/pages/profile/verify_signup.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profileupBlgdy3vLMeta?.name ?? "profile___fr",
    path: profileupBlgdy3vLMeta?.path ?? "/fr/profile",
    meta: profileupBlgdy3vLMeta || {},
    alias: profileupBlgdy3vLMeta?.alias || [],
    redirect: profileupBlgdy3vLMeta?.redirect,
    component: () => import("/app/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: verify_passwordgLQvYSM7fBMeta?.name ?? "profile-verify_password",
    path: verify_passwordgLQvYSM7fBMeta?.path ?? "verify_password",
    meta: verify_passwordgLQvYSM7fBMeta || {},
    alias: verify_passwordgLQvYSM7fBMeta?.alias || [],
    redirect: verify_passwordgLQvYSM7fBMeta?.redirect,
    component: () => import("/app/pages/profile/verify_password.vue").then(m => m.default || m)
  },
  {
    name: verify_signupoFYdrbpbWiMeta?.name ?? "profile-verify_signup",
    path: verify_signupoFYdrbpbWiMeta?.path ?? "verify_signup",
    meta: verify_signupoFYdrbpbWiMeta || {},
    alias: verify_signupoFYdrbpbWiMeta?.alias || [],
    redirect: verify_signupoFYdrbpbWiMeta?.redirect,
    component: () => import("/app/pages/profile/verify_signup.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profileupBlgdy3vLMeta?.name ?? "profile___ar",
    path: profileupBlgdy3vLMeta?.path ?? "/ar/profile",
    meta: profileupBlgdy3vLMeta || {},
    alias: profileupBlgdy3vLMeta?.alias || [],
    redirect: profileupBlgdy3vLMeta?.redirect,
    component: () => import("/app/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: verify_passwordgLQvYSM7fBMeta?.name ?? "profile-verify_password",
    path: verify_passwordgLQvYSM7fBMeta?.path ?? "verify_password",
    meta: verify_passwordgLQvYSM7fBMeta || {},
    alias: verify_passwordgLQvYSM7fBMeta?.alias || [],
    redirect: verify_passwordgLQvYSM7fBMeta?.redirect,
    component: () => import("/app/pages/profile/verify_password.vue").then(m => m.default || m)
  },
  {
    name: verify_signupoFYdrbpbWiMeta?.name ?? "profile-verify_signup",
    path: verify_signupoFYdrbpbWiMeta?.path ?? "verify_signup",
    meta: verify_signupoFYdrbpbWiMeta || {},
    alias: verify_signupoFYdrbpbWiMeta?.alias || [],
    redirect: verify_signupoFYdrbpbWiMeta?.redirect,
    component: () => import("/app/pages/profile/verify_signup.vue").then(m => m.default || m)
  }
]
  },
  {
    name: real_45estateRBx1btoobbMeta?.name ?? "real-estate___en",
    path: real_45estateRBx1btoobbMeta?.path ?? "/en/real-estate",
    meta: real_45estateRBx1btoobbMeta || {},
    alias: real_45estateRBx1btoobbMeta?.alias || [],
    redirect: real_45estateRBx1btoobbMeta?.redirect,
    component: () => import("/app/pages/real-estate.vue").then(m => m.default || m)
  },
  {
    name: real_45estateRBx1btoobbMeta?.name ?? "real-estate___es",
    path: real_45estateRBx1btoobbMeta?.path ?? "/es/real-estate",
    meta: real_45estateRBx1btoobbMeta || {},
    alias: real_45estateRBx1btoobbMeta?.alias || [],
    redirect: real_45estateRBx1btoobbMeta?.redirect,
    component: () => import("/app/pages/real-estate.vue").then(m => m.default || m)
  },
  {
    name: real_45estateRBx1btoobbMeta?.name ?? "real-estate___ru",
    path: real_45estateRBx1btoobbMeta?.path ?? "/ru/real-estate",
    meta: real_45estateRBx1btoobbMeta || {},
    alias: real_45estateRBx1btoobbMeta?.alias || [],
    redirect: real_45estateRBx1btoobbMeta?.redirect,
    component: () => import("/app/pages/real-estate.vue").then(m => m.default || m)
  },
  {
    name: real_45estateRBx1btoobbMeta?.name ?? "real-estate___fr",
    path: real_45estateRBx1btoobbMeta?.path ?? "/fr/real-estate",
    meta: real_45estateRBx1btoobbMeta || {},
    alias: real_45estateRBx1btoobbMeta?.alias || [],
    redirect: real_45estateRBx1btoobbMeta?.redirect,
    component: () => import("/app/pages/real-estate.vue").then(m => m.default || m)
  },
  {
    name: real_45estateRBx1btoobbMeta?.name ?? "real-estate___ar",
    path: real_45estateRBx1btoobbMeta?.path ?? "/ar/real-estate",
    meta: real_45estateRBx1btoobbMeta || {},
    alias: real_45estateRBx1btoobbMeta?.alias || [],
    redirect: real_45estateRBx1btoobbMeta?.redirect,
    component: () => import("/app/pages/real-estate.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45useA3Owpnmw23Meta?.name ?? "terms-of-use___en",
    path: terms_45of_45useA3Owpnmw23Meta?.path ?? "/en/terms-of-use",
    meta: terms_45of_45useA3Owpnmw23Meta || {},
    alias: terms_45of_45useA3Owpnmw23Meta?.alias || [],
    redirect: terms_45of_45useA3Owpnmw23Meta?.redirect,
    component: () => import("/app/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45useA3Owpnmw23Meta?.name ?? "terms-of-use___es",
    path: terms_45of_45useA3Owpnmw23Meta?.path ?? "/es/terms-of-use",
    meta: terms_45of_45useA3Owpnmw23Meta || {},
    alias: terms_45of_45useA3Owpnmw23Meta?.alias || [],
    redirect: terms_45of_45useA3Owpnmw23Meta?.redirect,
    component: () => import("/app/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45useA3Owpnmw23Meta?.name ?? "terms-of-use___ru",
    path: terms_45of_45useA3Owpnmw23Meta?.path ?? "/ru/terms-of-use",
    meta: terms_45of_45useA3Owpnmw23Meta || {},
    alias: terms_45of_45useA3Owpnmw23Meta?.alias || [],
    redirect: terms_45of_45useA3Owpnmw23Meta?.redirect,
    component: () => import("/app/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45useA3Owpnmw23Meta?.name ?? "terms-of-use___fr",
    path: terms_45of_45useA3Owpnmw23Meta?.path ?? "/fr/terms-of-use",
    meta: terms_45of_45useA3Owpnmw23Meta || {},
    alias: terms_45of_45useA3Owpnmw23Meta?.alias || [],
    redirect: terms_45of_45useA3Owpnmw23Meta?.redirect,
    component: () => import("/app/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45useA3Owpnmw23Meta?.name ?? "terms-of-use___ar",
    path: terms_45of_45useA3Owpnmw23Meta?.path ?? "/ar/terms-of-use",
    meta: terms_45of_45useA3Owpnmw23Meta || {},
    alias: terms_45of_45useA3Owpnmw23Meta?.alias || [],
    redirect: terms_45of_45useA3Owpnmw23Meta?.redirect,
    component: () => import("/app/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: thank_you_designerOlLS7dGGyrMeta?.name ?? "thank_you_designer___en",
    path: thank_you_designerOlLS7dGGyrMeta?.path ?? "/en/thank_you_designer",
    meta: thank_you_designerOlLS7dGGyrMeta || {},
    alias: thank_you_designerOlLS7dGGyrMeta?.alias || [],
    redirect: thank_you_designerOlLS7dGGyrMeta?.redirect,
    component: () => import("/app/pages/thank_you_designer.vue").then(m => m.default || m)
  },
  {
    name: thank_you_designerOlLS7dGGyrMeta?.name ?? "thank_you_designer___es",
    path: thank_you_designerOlLS7dGGyrMeta?.path ?? "/es/thank_you_designer",
    meta: thank_you_designerOlLS7dGGyrMeta || {},
    alias: thank_you_designerOlLS7dGGyrMeta?.alias || [],
    redirect: thank_you_designerOlLS7dGGyrMeta?.redirect,
    component: () => import("/app/pages/thank_you_designer.vue").then(m => m.default || m)
  },
  {
    name: thank_you_designerOlLS7dGGyrMeta?.name ?? "thank_you_designer___ru",
    path: thank_you_designerOlLS7dGGyrMeta?.path ?? "/ru/thank_you_designer",
    meta: thank_you_designerOlLS7dGGyrMeta || {},
    alias: thank_you_designerOlLS7dGGyrMeta?.alias || [],
    redirect: thank_you_designerOlLS7dGGyrMeta?.redirect,
    component: () => import("/app/pages/thank_you_designer.vue").then(m => m.default || m)
  },
  {
    name: thank_you_designerOlLS7dGGyrMeta?.name ?? "thank_you_designer___fr",
    path: thank_you_designerOlLS7dGGyrMeta?.path ?? "/fr/thank_you_designer",
    meta: thank_you_designerOlLS7dGGyrMeta || {},
    alias: thank_you_designerOlLS7dGGyrMeta?.alias || [],
    redirect: thank_you_designerOlLS7dGGyrMeta?.redirect,
    component: () => import("/app/pages/thank_you_designer.vue").then(m => m.default || m)
  },
  {
    name: thank_you_designerOlLS7dGGyrMeta?.name ?? "thank_you_designer___ar",
    path: thank_you_designerOlLS7dGGyrMeta?.path ?? "/ar/thank_you_designer",
    meta: thank_you_designerOlLS7dGGyrMeta || {},
    alias: thank_you_designerOlLS7dGGyrMeta?.alias || [],
    redirect: thank_you_designerOlLS7dGGyrMeta?.redirect,
    component: () => import("/app/pages/thank_you_designer.vue").then(m => m.default || m)
  },
  {
    name: thank_you_developerr71Kq0pOduMeta?.name ?? "thank_you_developer___en",
    path: thank_you_developerr71Kq0pOduMeta?.path ?? "/en/thank_you_developer",
    meta: thank_you_developerr71Kq0pOduMeta || {},
    alias: thank_you_developerr71Kq0pOduMeta?.alias || [],
    redirect: thank_you_developerr71Kq0pOduMeta?.redirect,
    component: () => import("/app/pages/thank_you_developer.vue").then(m => m.default || m)
  },
  {
    name: thank_you_developerr71Kq0pOduMeta?.name ?? "thank_you_developer___es",
    path: thank_you_developerr71Kq0pOduMeta?.path ?? "/es/thank_you_developer",
    meta: thank_you_developerr71Kq0pOduMeta || {},
    alias: thank_you_developerr71Kq0pOduMeta?.alias || [],
    redirect: thank_you_developerr71Kq0pOduMeta?.redirect,
    component: () => import("/app/pages/thank_you_developer.vue").then(m => m.default || m)
  },
  {
    name: thank_you_developerr71Kq0pOduMeta?.name ?? "thank_you_developer___ru",
    path: thank_you_developerr71Kq0pOduMeta?.path ?? "/ru/thank_you_developer",
    meta: thank_you_developerr71Kq0pOduMeta || {},
    alias: thank_you_developerr71Kq0pOduMeta?.alias || [],
    redirect: thank_you_developerr71Kq0pOduMeta?.redirect,
    component: () => import("/app/pages/thank_you_developer.vue").then(m => m.default || m)
  },
  {
    name: thank_you_developerr71Kq0pOduMeta?.name ?? "thank_you_developer___fr",
    path: thank_you_developerr71Kq0pOduMeta?.path ?? "/fr/thank_you_developer",
    meta: thank_you_developerr71Kq0pOduMeta || {},
    alias: thank_you_developerr71Kq0pOduMeta?.alias || [],
    redirect: thank_you_developerr71Kq0pOduMeta?.redirect,
    component: () => import("/app/pages/thank_you_developer.vue").then(m => m.default || m)
  },
  {
    name: thank_you_developerr71Kq0pOduMeta?.name ?? "thank_you_developer___ar",
    path: thank_you_developerr71Kq0pOduMeta?.path ?? "/ar/thank_you_developer",
    meta: thank_you_developerr71Kq0pOduMeta || {},
    alias: thank_you_developerr71Kq0pOduMeta?.alias || [],
    redirect: thank_you_developerr71Kq0pOduMeta?.redirect,
    component: () => import("/app/pages/thank_you_developer.vue").then(m => m.default || m)
  },
  {
    name: _91id_93vBwXVvXpAaMeta?.name ?? "verify-id___en",
    path: _91id_93vBwXVvXpAaMeta?.path ?? "/en/verify/:id()",
    meta: _91id_93vBwXVvXpAaMeta || {},
    alias: _91id_93vBwXVvXpAaMeta?.alias || [],
    redirect: _91id_93vBwXVvXpAaMeta?.redirect,
    component: () => import("/app/pages/verify/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93vBwXVvXpAaMeta?.name ?? "verify-id___es",
    path: _91id_93vBwXVvXpAaMeta?.path ?? "/es/verify/:id()",
    meta: _91id_93vBwXVvXpAaMeta || {},
    alias: _91id_93vBwXVvXpAaMeta?.alias || [],
    redirect: _91id_93vBwXVvXpAaMeta?.redirect,
    component: () => import("/app/pages/verify/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93vBwXVvXpAaMeta?.name ?? "verify-id___ru",
    path: _91id_93vBwXVvXpAaMeta?.path ?? "/ru/verify/:id()",
    meta: _91id_93vBwXVvXpAaMeta || {},
    alias: _91id_93vBwXVvXpAaMeta?.alias || [],
    redirect: _91id_93vBwXVvXpAaMeta?.redirect,
    component: () => import("/app/pages/verify/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93vBwXVvXpAaMeta?.name ?? "verify-id___fr",
    path: _91id_93vBwXVvXpAaMeta?.path ?? "/fr/verify/:id()",
    meta: _91id_93vBwXVvXpAaMeta || {},
    alias: _91id_93vBwXVvXpAaMeta?.alias || [],
    redirect: _91id_93vBwXVvXpAaMeta?.redirect,
    component: () => import("/app/pages/verify/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93vBwXVvXpAaMeta?.name ?? "verify-id___ar",
    path: _91id_93vBwXVvXpAaMeta?.path ?? "/ar/verify/:id()",
    meta: _91id_93vBwXVvXpAaMeta || {},
    alias: _91id_93vBwXVvXpAaMeta?.alias || [],
    redirect: _91id_93vBwXVvXpAaMeta?.redirect,
    component: () => import("/app/pages/verify/[id].vue").then(m => m.default || m)
  }
]