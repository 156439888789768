import { defineStore } from 'pinia'

export const useDirectionStore = defineStore('direction', {
    state: () => 
    ({
        isRight: false
    } as {isRight: boolean}),
    actions: {
        setDirection(value: boolean){
            this.isRight = value
        }
    }
})