export const ListLang = [
  {label: 'English', value: 'en'},
  {label: 'Français', value: 'fr'},
  {label: 'Español', value: 'es'},
  {label: 'Русский', value: 'ru'},
  {label: 'العربية', value: 'ar'},
];

export const ListLangSubtitles = [
  {label: 'English', value: 'en'},
  {label: 'Français', value: 'fr'},
  {label: 'Español', value: 'es'},
  {label: 'Русский', value: 'ru'},
  {label: 'العربية', value: 'ar'},
];

export enum ListErrorRegister {
  STATUS_401 = 'regStatus401',
  STATUS_AUTH_401 = 'regStatus401_AUTH',
  STATUS_404 = 'regStatus404',
  STATUS_409 = 'regStatus409',
  STATUS_429 = 'regStatus429',
  STATUS_500 = 'regStatus500',
  STATUS_502 = 'regStatus502',
  STATUS_503 = 'regStatus503',
  STATUS_504 = 'regStatus504',
  STATUS_DEFAULT = 'regStatusDefault',
}

export const tarifPlansForOfferPage = {
  FREE: {
    id: 0,
    name: 'FREE',
    style: 'base',
    price: {
      ru: '0 ₽',
      en: '$0',
      ruY: '0 ₽',
      enY: '$0',
    },
    list: {
      ok: [
        'tariffs_allFunctions',
        'tariffs_savePlans3',
      ],
      accent: ['designSaveUnavailable'],
      hint: [
        'watermarksRemoval',
        'prioritySup',
        'tariffs_education'
      ],
    },
    button: {
      text: 'download',
      download: true,
    },
  },
  STANDARD: {
    id: 1,
    timer: true,
    name: 'STANDARD',
    style: 'accent',
    price: {
      ru: '750 ₽',
      en: '$15',
      ruY: '5000 ₽',
      enY: '$100',
      saleRu: '500 ₽',
      saleEn: '$10',
    },
    list: {
      ok: [
        'tariffs_allFunctions',
        'tariffs_savePlans5',
        'tariffs_maxSave',
        'watermarksRemoval'
      ],
      hint: [
        'prioritySup',
        'tariffs_education'
      ],
    },
    button: {
      text: 'subscribeTitle',
      path: '/pay/standard',
      pathY: '/pay/standardyear',
      download: false,
    },
  },
  PREMIUM: {
    id: 2,
    name: 'PREMIUM',
    style: 'base',
    price: {
      ru: '1500 ₽',
      en: '$25',
      ruY: '15000 ₽',
      enY: '$250',
    },
    list: {
      ok: [
        'tariffs_allFunctions',
        'tariffs_savePlans20',
        'tariffs_maxSave',
        'watermarksRemoval',
        'prioritySup',
        'tariffs_education'
      ],
    },
    button: {
      text: 'subscribeTitle',
      path: '/pay/premium',
      pathY: '/pay/premiumyear',
      download: false,
    },
  },
  PROPERTY: {
    id: 3,
    name: 'PROPERTY DEVELOPERS',
    style: 'base',
    price: {
      ru: '17500 ₽',
      en: '$290',
      ruY: '175000 ₽',
      enY: '$2900',
    },
    list: {
      ok: [
        'tariffs_allFunctions',
        'tariffs_addToSite15',
        'tariffs_savePlans100',
      ],
    },
    button: {
      text: 'subscribeTitle',
      path: '/pay/property',
      pathY: '/pay/propertyyear',
      download: false,
    },
  },
  ENTERPRISE: {
    id: 4,
    name: 'ENTERPRISE',
    style: 'base',
    price: {
      ru: '24900 ₽',
      en: '$390',
      ruY: '249000 ₽',
      enY: '$3900',
    },
    list: {
      ok: [
        'tariffs_allFunctions',
        'tariffs_addToSite15',
        'tariffs_savePlans100',
        'tariffs_addToDream'
      ],
    },
    button: {
      text: 'subscribeTitle',
      path: '/pay/enterprise',
      pathY: '/pay/enterpriseyear',
      download: false,
    },
  },
};

export const tarifPlansForFurnitureCompaniesPage = {
  START: {
    id: 3,
    title: 'Start',
    subtitle: 'planForPersonalInterior',
    priceEn: 'free',
    priceRu: 'free',
    discountPercent: undefined,
    discountPriceEn: undefined,
    discountPriceRu: undefined,
    planIncludeArray: [
      'projects3Month',
      'modelLibrary',
      'ownLayouts',
      'savingLayout',
      'videoFly3',
      'tours3DMonth3',
      'vrMode',
    ],
    priceColor: 'var(--var-black-200)',
  },
  ENTERPRICE: {
    id: 2,
    title: 'Enterprise',
    subtitle: 'offerPlanAlready',
    priceEn: '$390',
    priceRu: '3900₽',
    discountPercent: undefined,
    discountPriceEn: undefined,
    discountPriceRu: undefined,
    planIncludeArray: [
      'designFeatures',
      'projects15Month',
      'uploadModelsTextures100',
      'uploadMaterials100',
      'showingModelsWebsite2',
      'showingModelsWebsite',
      'integrate3DToursWeb',
      'prioritySupport',
      {
        title: 'increasedLimitsForAccount',
        subtitle: 'projects100video200',
      },
      'addMoreContact',
    ],
  },
};

export const ListLangDFlat = [{label: 'Русский', value: 'ru'}];

export const AnalyticTags = {
  login: {value: 'login', isNeedPostfixLang: false},
  sign_up: {value: 'sign_up', isNeedPostfixLang: true},
  authorisation_google: {
    value: 'authorisation_google',
    isNeedPostfixLang: false,
  },
};

export enum AnalyticTagsPostFix {
  DFLAT = 'dflat',
  OFFER = 'offer',
  MAIN = 'main',
}

export enum SessingStorageKey {
  // get all utm
  df_utm = 'df_utm',
}
