import {getLocaleList} from '~/helpers/getLocaleList';

export function getLocaleFromPath(path: string) {
  const localesList = getLocaleList();
  const newPath = path?.split('/')[1];
  if (newPath.length > 2) {
    return undefined;
  } else {
    return localesList.find((lang) => newPath.includes(lang));
  }
}
