import {defineStore} from 'pinia';
import type {User} from '~/interfaces';
import {api} from '~/plugins/axios';
import {useCookie} from '#imports';
import Logger from '~/helpers/logger';
import type {IGetUserResponse} from '~/interfaces/Responses';
import {AxiosError} from 'axios';
import {SessingStorageKey} from '~/constants';
import {useRoute} from "nuxt/app";

const userLogger = Logger.getInstance();

export const useUserStore = defineStore('user', {
  state: () =>
    ({
      email: '',
      username: '',
      user_avatar: '',
      isLoggedIn:
        useCookie('df').value?.split('.').length === 3 || false,
      error: undefined,
      user_id: 0,
      current_tariff_params: null,
      active: false,
      isShowLoginModal: false,
      countryCode: '',
      region: null,
      specialLink: '',
    } as User),
  actions: {
    async getProfile() {
      try {
        const token = useCookie('df');

        const response = await api.get('/profile/get', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${token.value}`,
          },
        });
        const user = response.data.payload as IGetUserResponse;
        this.isLoggedIn = true;
        this.email = user.email;
        this.user_avatar = user.user_avatar;
        // this.username = response.data.username
        // this.user_id = response.data.user_id
        this.active = user.active;
        this.current_tariff_params = user.current_tariff_params;
        this.countryCode = user.countryCode;
        this.region = user.region;
        userLogger.info('[USER] getProfile()');
      } catch (e: any) {
        if (
          e.response?.status === 401 ||
          e.response?.status === 403 ||
          e.response?.status === 404 ||
          e.response?.status === 410 ||
          e.response?.status === 422
        ) {
          this.isLoggedIn = false;
          const df = useCookie('df');
          df.value = null;
          userLogger.error(
            '[USER] getProfile()',
            JSON.stringify(e.response.data),
          );
          useRouter().push('/');
        }
      }
    },
    async register(
      email: string,
      username: string,
      password: string,
    ) {
      try {
        const route = window.location.href.includes('d-flat') ? 'd-flat.ru' : 'dreamflat.design';

        const response = await api.post(`/profile/signup?dns_name=${route}`, {
          email,
          username,
          password,
        });

        userLogger.info('[USER] register()');
        if (response.data.error === '') {
          return {error: false, data: response.data};
        } else {
          return {error: false, data: response.data};
        }
      } catch (err: any) {
        userLogger.error(
          '[USER] register()',
          `${err.response.config.url} ${err.response.status} (${err.response.statusText})\n ${err.response.data.detail}`,
        );
        console.log(err);
        return {
          error: true,
          data: err.response.data,
          status: err.response?.status,
        };
      }
    },
    async login(email: string, password: string) {
      try {
        const response = await api.post('/profile/login', {
          email,
          password,
        });

        if (!response.data.error) {
          this.isLoggedIn = true;
          const df = useCookie('df');
          const dfr = useCookie('dfr');

          df.value = response.data.payload.token;
          dfr.value = response.data.payload.refresh_token;

          const user = response.data.payload.user;
          this.email = user.email;
          this.user_avatar = user.user_avatar;
          this.active = user.active;
          this.current_tariff_params = user.current_tariff_params;
          createEvent(undefined, 'login', df.value);
          userLogger.info('[USER] login()');
          return {
            error: false,
            data: response.data,
          };
        }

        return {
          error: false,
          data: response.data,
        };
      } catch (err: any) {
        userLogger.error('[USER] login()', `${err}`);
        console.log(err);
        return {
          error: true,
          data: err,
          status: err.response?.status,
        };
      }
    },
    async recover(email: string, password: string) {
      try {
        const response = await api.post('/profile/reset_password', {
          email,
          password,
        });
        userLogger.info('[USER] recover()');
        return response.data;
      } catch (err: any) {
        const messageFromServer = err.response?.data?.error
          ?.replaceAll(' ', '')
          ?.toLowerCase();

        userLogger.error(
          '[USER] recover()',
          JSON.stringify(err.response.data),
        );
        console.log('reset_password');
        console.log(err);

        return {
          error: true,
          message: messageFromServer || err.toString(),
          status: err?.response?.status,
        };
      }
    },
    async deleteProfile() {
      // const runtimeConfig = useRuntimeConfig()
      const token = useCookie('df');
      await api.delete('/profile/delete', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token.value}`,
        },
      });
      // this.getProfile()
      this.isLoggedIn = false;
      const df = useCookie('df');
      df.value = null;
      userLogger.info('[USER] deleteProfile()');
      // location.href = '/'
      useRouter().push('/');
    },
    uploadImage() {
      // const runtimeConfig = useRuntimeConfig()
      const token = useCookie('df');
      try {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/png, image/gif, image/jpeg';
        input.click();
        input.addEventListener('change', async (e) => {
          const formData = new FormData();
          const target = e.target as HTMLInputElement;

          const files = target.files![0];
          formData.append('file', files);
          // console.log(form.get('avatar'))
          await api.post('/profile/upload_avatar', formData, {
            headers: {
              Authorization: `Bearer ${token.value}`,
            },
          });
          this.getProfile();
          userLogger.info('[USER] uploadImage()');
        });
      } catch (error) {
        userLogger.error('[USER] recover()', JSON.stringify(error));
        console.log(error);
      }
    },
    async changePassword(new_password: string) {
      const response = await api.post('/profile/reset_password', {
        email: this.email,
        password: new_password,
      });
      userLogger.info('[USER] changePassword()');
      return response.data;
    },
    async changeUsername(username: string) {
      const token = useCookie('df');
      try {
        await api.patch(
          'https://dreamflat.design/api/profile/edit',
          {username},
          {
            headers: {
              Authorization: token.value,
            },
          },
        );
        this.getProfile();
        userLogger.info('[USER] changeUsername()');
      } catch (e) {
        userLogger.error(
          '[USER] changeUsername()',
          JSON.stringify(e),
        );
      }
    },
    async verifyToken(token: string) {
      // const runtimeConfig = useRuntimeConfig()

      try {
        const response = await api.get(
          `https://dreamflat.design/api/profile/verify_signup/${token}`,
        );
        userLogger.info('[USER] verifyToken()');
        return {err: false, data: response.data};
      } catch (e: any) {
        userLogger.error('[USER] verifyToken()', JSON.stringify(e));
        return {err: true, data: e.response.data};
      }
    },
    async verifyEmail(email: string, access_code: string) {
      // const runtimeConfig = useRuntimeConfig()
      try {
        const response = await api.post(
          // runtimeConfig.public.vueAppApiUrl + 'profile/verify_signup',
          // 'https://dreamflat.design/proxy/profile/verify_signup',
          '/profile/verify_signup',
          {
            email,
            access_code,
          },
        );
        if (
          !response?.data?.error &&
          !response?.data?.payload?.error
        ) {
          createEvent(
            undefined,
            'sign_up',
            response?.data?.payload?.token,
          );
          appSignUp();
        }
        return response.data;
      } catch (err: any) {
        userLogger.error(
          '[USER] verifyEmail()',
          JSON.stringify(err.response.data),
        );
        return {
          error: true,
          data: err.response.data,
          status: err.response?.status,
        };
      }
    },
    async verifyPassword(email: string, access_code: string) {
      // const runtimeConfig = useRuntimeConfig()
      try {
        const response = await api.post('/profile/verify_password', {
          email,
          access_code,
        });
        userLogger.info('[USER] verifyPassword()');
        return response.data;
      } catch (err: any) {
        userLogger.error(
          '[USER] verifyPassword()',
          JSON.stringify(err.response.data),
        );
        console.log('verify_password');
        console.log(err);

        return {
          error: true,
          data: err.response.data,
          status: err?.response?.status,
        };
      }
    },

    // КОСТЫЛЬ
    async setTarif(tarif: string) {
      const token = useCookie('df');
      const tarifIds: {[key: string]: number} = {
        designer: 3,
        premium: 2,
        enterprise: 4,
      };
      const tariff_id = tarifIds[tarif];
      try {
        await api.put(
          'https://dreamflat.design/api/tariffs/grant_tariff',
          {tariff_id},
          {
            headers: {
              Authorization: `Bearer ${token.value}`,
            },
          },
        );
        userLogger.info(`[USER] success setTarif()`);
        return 'OK';
      } catch (e) {
        userLogger.error(`[USER] setTarif()`);
        return 'BAD';
      }
    },

    logout() {
      this.isLoggedIn = false;
      const df = useCookie('df');
      const dfr = useCookie('dfr');

      df.value = null;
      dfr.value = null;

      useRouter().push('/');
      // location.href = '/'
      userLogger.info('[USER] logout()');
    },

    showLoginModal(
      brokeConditionFunction?: CallableFunction,
      callbackFunction?: CallableFunction,
    ) {
      if (this.isLoggedIn && brokeConditionFunction) {
        brokeConditionFunction();
        return;
      }
      this.isShowLoginModal = true;
      if (callbackFunction) callbackFunction();
    },
    hideLoginModal() {
      userLogger.info(`[USER] hideLoginModal()`);
      this.isShowLoginModal = false;
      document.body.style.removeProperty('position');
      document.body.style.removeProperty('top');
      document.body.style.removeProperty('height');
    },
    async loginExternal(displayName: string | null, accessToken: string) {
      try {
        const response = await api.post('/profile/login/external', {
          displayName,
          accessToken,
        });
        userLogger.info(`[USER] try loginExternal()`);

        if (!response.data.error) {
          const df = useCookie('df');
          const dfr = useCookie('dfr');
          df.value = response.data.payload.token;
          dfr.value = response.data.payload.refresh_token;

          setTimeout(() => {
            this.getProfile();
            const route = useRoute();
            if (route.redirectedFrom) {
              setTimeout(() => {
                document.body.scrollIntoView(true);
              }, 700);
            }
          });
          return {
            error: false,
            data: 'OK',
            token: response.data.payload.token,
          };
        } else {
          return {
            error: true,
            data: response.data.error,
            status: response?.status,
          };
        }
      } catch (error) {
        const err = error as AxiosError;
        userLogger.error(
          '[USER] loginExternal()',
          `${err.config?.baseURL}${err.config?.url} ${err.response?.status} ${err.response?.statusText}`,
        );

        return {
          error: true,
          data: err.response?.data,
          status: err.response?.status,
        };
      }
    },
    async sendFeedbackByEmail(
      name: string,
      email: string,
      message: string,
      successCallback?: CallableFunction,
    ) {
      try {
        const response = await api.post('/profile/send_mail', {
          destination: 'support@dreamflat.design',
          subject: `${name} - ${email}`,
          body: message,
        });
        if (response.data.error) {
          userLogger.error('[USER] sendFeedbackByEmail()');
          return {error: 'Server error'};
        } else {
          userLogger.info('[USER] success sendFeedbackByEmail()');
          if (successCallback) successCallback();
        }
      } catch (err) {
        userLogger.error('[USER] sendFeedbackByEmail()');
        return {error: 'Server error'};
      }
    },
    async sendUtmTags(token?: string) {
      const utmTags = sessionStorage.getItem(
        SessingStorageKey.df_utm,
      );
      if (!utmTags) {
        return;
      }

      try {
        await api.post(
          '/profile/set_utm',
          {
            utm: utmTags,
          },
          {
            headers: {
              Authorization: `Bearer ${
                useCookie('df').value || token
              }`,
            },
          },
        );
        userLogger.info('[USER] success sendUtmTags()');
        sessionStorage.removeItem(SessingStorageKey.df_utm);
      } catch (error) {
        userLogger.error('[USER] sendUtmTags()');
        return {error: 'Server error'};
      }
    },
  },
});
