import Joi from 'joi'

const pathValidator = Joi.string().min(5).required()
const sha512Validator = Joi.string().min(88).max(88).required()

export const launcherInfoSchema = Joi.object({
  version: Joi.string()
    .pattern(/^[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}(-[0-9]{1,4})?$/)
    .required(),
  files: Joi.array()
    .items(
      Joi.object({
        url: pathValidator,
        sha512: sha512Validator,
        size: Joi.number().required(),
      })
    )
    .required(),
  path: pathValidator,
  sha512: sha512Validator,
  releaseDate: Joi.string()
    .pattern(
      /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}Z$/
    )
    .required(),
  releaseNotes: Joi.string(),
  changelog: Joi.object(),
})
