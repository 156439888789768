const isDev = process.env.NODE_ENV !== 'production';

export default class Logger {
  private static instance: Logger;
  private _stack: Array<string>;

  constructor() {
    this._stack = [];
  }

  public static getInstance(): Logger {
    if (!Logger.instance) {
      Logger.instance = new Logger();
    }
    return Logger.instance;
  }

  public debug(message: any): void {
    if (isDev) {
      console.log(`[${this.getTime()}] [DEBUG] - ${message}`);
    }
  }

  public info(message: any): void {
    const logMessage = `[${this.getTime()}] [INFO] - ${message}`;
    if (isDev) {
      console.log(logMessage);
    }
    this._stack.push(logMessage);
  }

  public error(method: string, message: any): void {
    const errMessage = `[${this.getTime()}] [ERROR] - ${method}: ${message}`;
    if (isDev) {
      console.log(errMessage);
    }
    this._stack.push(errMessage);
  }

  public download(): void {
    const textContent = this._stack.length
      ? this._stack.join('\n')
      : 'Empty logs';
    const blob = new Blob([textContent], {type: 'text/plain'});

    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = this.createFileName();

    a.click();
    URL.revokeObjectURL(a.href);
  }

  private getTime(): string {
    const t = new Date();
    return (
      `${`0${t.getHours()}`.slice(-2)}` +
      `:${`0${t.getMinutes()}`.slice(-2)}` +
      `:${`0${t.getSeconds()}`.slice(-2)}`
    );
  }

  private createFileName(): string {
    const date = new Date();
    return (
      `log-${`0${date.getDate()}`.slice(-2)}` +
      `.${`0${date.getMonth() + 1}`.slice(-2)}` +
      `.${date.getFullYear()}` +
      `_${`0${date.getHours()}`.slice(-2)}` +
      `_${`0${date.getMinutes()}`.slice(-2)}` +
      `_${`0${date.getSeconds()}`.slice(-2)}.log`
    );
  }
}
