// @ts-nocheck
/* eslint-disable */
import axios from 'axios';
import {AnalyticTagsPostFix, AnalyticTags} from '~/constants/index';
import {getLocaleFromPath} from '~/helpers/getLocaleFromPath';
import {useCookie} from '#imports';
import {useUserStore} from '~/modules/User/user.store';

export function loginConversion(url?: string) {
  // const callback = function () {
  //   if (typeof url != 'undefined') {
  //     window.location.href = url;
  //   }
  // };
  // try {
  //   gtag('event', 'login', {
  //     send_to: 'G-FWNKP1HTW5/KrHgDQAiMYtswpfIVKfs',
  //     event_callback: callback,
  //   });
  // } catch (e) {
  //   console.debug(e);
  // }
  // return false;
}

export function purchaseConversion(url?: string) {
  // const callback = function () {
  //   if (typeof url != 'undefined') {
  //     window.location.href = url;
  //   }
  // };
  // try {
  //   gtag('event', 'purchase', {
  //     send_to: 'G-FWNKP1HTW5/KrHgDQAiMYtswpfIVKfs',
  //     event_callback: callback,
  //   });
  // } catch (e) {
  //   console.debug(e);
  // }
  // return false;
}

export function basketConversion(url?: string) {
  // var callback = function () {
  //   if (typeof url != 'undefined') {
  //     window.location = url;
  //   }
  // };
  // try {
  //   gtag('event', 'conversion', {
  //     send_to: 'AW-11094169251/LoNpCOTawpYYEKOtjqop',
  //     event_callback: callback,
  //   });
  // } catch (e) {
  //   console.debug(e);
  // }
  // return false;
}

export function appDownload(url?: string) {
  let callback = function () {
    if (typeof url != 'undefined') {
      window.location = url;
    }
  };
  try {
    gtag('event', 'app_download', {
      send_to: 'G-FWNKP1HTW5/wgLnCOfawpYYEKOtjqoJ',
      event_callback: callback,
    });
    console.log('ok');
  } catch (e) {
    console.log(e);
  }
}

export function appSignUp(url?: string) {
  let callback = function () {
    if (typeof url != 'undefined') {
      window.location = url;
    }
  };
  try {
    gtag('event', 'app_sign_up', {
      send_to: 'G-FWNKP1HTW5/wgLnCOfawpYYEKOtjqop',
      event_callback: callback,
    });
  } catch (e) {
    console.log(e);
  }
}

export function authorisationGoogle(url?: string) {
  let callback = function () {
    if (typeof url != 'undefined') {
      window.location = url;
    }
  };
  try {
    gtag('event', 'authorisation_google', {
      send_to: 'G-FWNKP1HTW5/wgLnCOfawpYYEKOtjqop',
      event_callback: callback,
    });
  } catch (e) {
    console.log(e);
  }
}

export function gaUserVisitedOfferPage(url?: string) {
  let callback = function () {
    if (typeof url != 'undefined') {
      window.location = url;
    }
  };
  try {
    gtag('event', 'visited_offer_page', {
      send_to: 'G-FWNKP1HTW5/wgLnCOfawpYYEKOtjqop',
      event_callback: callback,
    });
  } catch (e) {
    console.log(e);
  }
}

export function gaUserVisitedOfferAboutPage(url?: string) {
  let callback = function () {
    if (typeof url != 'undefined') {
      window.location = url;
    }
  };
  try {
    gtag('event', 'visited_offer_about_page', {
      send_to: 'G-FWNKP1HTW5/wgLnCOfawpYYEKOtjqop',
      event_callback: callback,
    });
  } catch (e) {
    console.log(e);
  }
}

export function gaUserVisitedOfferGalleryPage(url?: string) {
  let callback = function () {
    if (typeof url != 'undefined') {
      window.location = url;
    }
  };
  try {
    gtag('event', 'visited_offer_gallery_page', {
      send_to: 'G-FWNKP1HTW5/wgLnCOfawpYYEKOtjqop',
      event_callback: callback,
    });
  } catch (e) {
    console.log(e);
  }
}

export function gaUserVisitedOfferPlansPage(url?: string) {
  let callback = function () {
    if (typeof url != 'undefined') {
      window.location = url;
    }
  };
  try {
    gtag('event', 'visited_offer_plans_page', {
      send_to: 'G-FWNKP1HTW5/wgLnCOfawpYYEKOtjqop',
      event_callback: callback,
    });
  } catch (e) {
    console.log(e);
  }
}

export function gaUserVisitedOfferPayDesignerTarif(url?: string) {
  let callback = function () {
    if (typeof url != 'undefined') {
      window.location = url;
    }
  };
  try {
    gtag('event', 'visited_offer_pay_designer_tarif', {
      send_to: 'G-FWNKP1HTW5/wgLnCOfawpYYEKOtjqop',
      event_callback: callback,
    });
  } catch (e) {
    console.log(e);
  }
}

export function gaUserVisitedOfferPayEnterpriseTarif(url?: string) {
  let callback = function () {
    if (typeof url != 'undefined') {
      window.location = url;
    }
  };
  try {
    gtag('event', 'visited_offer_pay_enterprise_tarif', {
      send_to: 'G-FWNKP1HTW5/wgLnCOfawpYYEKOtjqop',
      event_callback: callback,
    });
  } catch (e) {
    console.log(e);
  }
}

export function gaUserPaymentTarifAndStatus(
  tarifName: string,
  status: TPayStatus,
  url?: string,
) {
  let callback = function () {
    if (typeof url != 'undefined') {
      window.location = url;
    }
  };
  try {
    gtag(
      'event',
      `payment_offer_page_${tarifName}_status_${status}`,
      {
        send_to: 'G-FWNKP1HTW5/wgLnCOfawpYYEKOtjqop',
        event_callback: callback,
      },
    );
  } catch (e) {
    console.log(e);
  }
}

export function purchaseConv(name?: string, url?: string) {
  const callback = function () {
    if (typeof url != 'undefined') {
      window.location.href = url;
    }
  };
  try {
    gtag('event', name, {
      send_to: 'G-FWNKP1HTW5/KrHgDQAiMYtswpfIVKfs',
      event_callback: callback,
    });
  } catch (e) {
    console.debug(e);
  }
  return false;
}

export function gaUserDesignStudioStatusPay(
  status: TPayStatus,
  url?: string,
) {
  let callback = function () {
    if (typeof url != 'undefined') {
      window.location = url;
    }
  };
  try {
    gtag('event', `payment_design_studio_status_${status}`, {
      send_to: 'G-FWNKP1HTW5/wgLnCOfawpYYEKOtjqop',
      event_callback: callback,
    });
    ym(
      91757379,
      'reachGoal',
      `payment_design_studio_status_${status}`,
    );
  } catch (e) {
    console.log(e);
  }
}

export function createEvent(
  user_id: number | undefined,
  event: string,
  token?: string | null,
) {
  const runtimeConfig = useRuntimeConfig();
  const currentToken = token || useCookie('df').value || null;
  const isBeta =
    location.href.includes('work.') ||
    location.href.includes('localhost') ||
    location.href.includes('127.0.0.1');
  const isDflat =
    runtimeConfig.public.vueAppBaseUrl.includes('d-flat');
  const route = useRoute();
  let currentEvent = event;
  const localeFromPath = getLocaleFromPath(route.fullPath);

  if (AnalyticTags[event]?.isNeedPostfixLang) {
    currentEvent += `_${localeFromPath}`;
  }
  switch (true) {
    case !AnalyticTags[event]:
      break;
    case isDflat:
      currentEvent += `_${AnalyticTagsPostFix.DFLAT}`;
      break;
    case route.fullPath.includes('/offer'):
      currentEvent += `_${AnalyticTagsPostFix.OFFER}`;
      break;
    default:
      currentEvent += `_${AnalyticTagsPostFix.MAIN}`;
  }
  axios
    .post(
      isBeta
        ? 'https://dreamflat.design/metric/statistic/create_dev'
        : 'https://dreamflat.design/metric/statistic/create_prod',

      {
        user_id,
        date_time: Date.now(),
        launcher_event_name: currentEvent,
      },
      {
        headers: {
          'Content-type': 'application/json',
          baseToken: `labocailalabra16`,
          ...(currentToken && {
            Authorization: `Bearer ${currentToken}`,
          }),
        },
      },
    )
    .catch((e) => {
      console.debug('ERROR');
    });

  const user = useUserStore();

  if (
    event === AnalyticTags.authorisation_google.value ||
    event === AnalyticTags.sign_up.value
  ) {
    user.sendUtmTags(token);
  }
}
