import {inject} from 'vue';
import type {App, InjectionKey} from 'vue';
import axios from 'axios';
import countiresJson from '~/i18n/countries.json';
import realtyJson from '~/i18n/realty.json';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $realty: (key: string) => string;
    $loc: (key: string) => string;
    $ct: (key: string) => string;
    $t: (key: string, $plural?: {[key: string]: string}) => string;
  }
}

interface LocalizationObject {
  [key: string]: {
    ru_RU: string;
    en_EN: string;
  };
}

interface Localization<L> {
  currentLocale: any;
  install: (app: App) => void;
  // get: (key: string) => string;
  getRealty: (key: string) => string;
  getRealtyRequest: () => Promise<void>;
  getCountriesRequest: () => Promise<void>;
  ct: (key: string) => string;
  map: Record<string, Record<keyof L, string>>;
  realty: L;
  externalCountries: L;
}

const LOCALE_KEY: InjectionKey<Localization<any>> =
  Symbol('LOCALE_KEY');

export function useLocale(): Localization<any> {
  return inject(LOCALE_KEY) as Localization<any>;
}
function extractLanguageCode(url: string) {
  try {
    const languageCode = new URL(url).pathname.split('/')[1];
    return languageCode;
  } catch (error) {
    console.error('Error extracting language code:', error);
    return null;
  }
}

export const localePlugin: Localization<LocalizationObject> = {
  map: {},
  realty: {},
  externalCountries: {},
  currentLocale: 'en_EN',
  install(app: App) {
    app.provide(LOCALE_KEY, this as any);
    app.config.globalProperties.$realty = this.getRealty.bind(this);
    app.config.globalProperties.$ct = this.ct.bind(this);
  },
  ct(key) {
    const currentLocale = `${extractLanguageCode(
      location.href,
    )}_${extractLanguageCode(location.href)?.toUpperCase()}`;
    if (this.externalCountries[key]) {
      return this.externalCountries[key][currentLocale];
    }
    return key + '_No_translated';
  },
  async getCountriesRequest() {
    if (Object.keys(this.externalCountries).length !== 0) return;
    await axios
      .get(
        'https://dreamflat.design/locale/sheet/translate?locale=countries',
      )
      .then((e) => {
        if (typeof e.data === 'object') {
          if (Object.keys(e.data).length > 1) {
            this.externalCountries = e.data;
          }
        }
      })
      .catch((e) => {
        console.debug(e);
        this.externalCountries = countiresJson;
      });
  },
  /**
   * function for get translated realty, used in realty page
   */
  async getRealtyRequest() {
    if (Object.keys(this.realty).length !== 0) return;
    await axios
      .get(
        'https://dreamflat.design/locale/sheet/translate?locale=realty',
      )
      .then((e) => {
        if (typeof e.data === 'object') {
          if (Object.keys(e.data).length >= 1) {
            this.realty = e.data;
          }
        }
      })
      .catch((e) => {
        console.debug(e);
        this.realty = realtyJson;
      });
  },
  getRealty(key) {
    try {
      location;
    } catch (e) {
      console.debug(1);
    }
    const currentLocale = `${extractLanguageCode(
      location.href,
    )}_${extractLanguageCode(location.href)?.toUpperCase()}`;

    if (this.realty[key]) {
      if (this.realty[key][currentLocale]) {
        return this.realty[key][currentLocale];
      }
    }
    return key + '_No_translated';
  },
};

export default defineNuxtPlugin(({vueApp}) => {
  vueApp.use(localePlugin);
});
