import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import locale_45global from "/app/middleware/locale.global.ts";
import redirect_45trailing_45slash_45global from "/app/middleware/redirect-trailing-slash.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  locale_45global,
  redirect_45trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/app/middleware/auth.ts")
}