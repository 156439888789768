import { defineNuxtPlugin } from '#app';
import axios from 'axios';
import { useRuntimeConfig, useCookie } from '#imports';
import {navigateTo} from "nuxt/app";

let isBeta;
let isDflat;
if (process.client) {
  isBeta =
    window.location.href.includes('work.') ||
    window.location.href.includes('localhost') ||
    location.href.includes('127.0.0.1');
  isDflat = window.location.href.includes('d-flat');
}

const api = axios.create({
  baseURL: `https://${isBeta ? 'work.' : ''}dreamflat.design/${isBeta ? 'api' : 'proxy'}`,
});

const apiPayment = axios.create({
  baseURL: `https://${isBeta ? 'work.' : ''}${isDflat ? 'd-flat.ru/' : 'dreamflat.design/'}`,
});

export default defineNuxtPlugin(() => {
  const df = useCookie('df');
  const dfr = useCookie('dfr');

  const setupInterceptors = (apiInstance) => {
    apiInstance.interceptors.response.use(
      response => response,
      async (error) => {
        if (error.response && error.response.status === 403) {
          // Попытка обновить токен
          try {
            const refreshResponse = await apiInstance.post('/profile/refresh_token', {}, {
              headers: {
                Authorization: dfr.value,
              }
            });

            const newAccessToken = refreshResponse.data.payload.token;
            const newRefreshToken = refreshResponse.data.payload.refresh_token;

            df.value = newAccessToken; // Сохраняем новый токен в cookie
            dfr.value = newRefreshToken; // Сохраняем новый токен в cookie
            console.log(newAccessToken);

            // Повторяем оригинальный запрос с новым токеном
            error.config.headers['Authorization'] = `${newAccessToken}`;
            return axios(error.config);
          } catch (refreshError) {
            // Если обновление токена не удалось, перенаправляем пользователя на страницу входа
            console.error('Ошибка обновления токена', refreshError);
            navigateTo('/');
            return Promise.reject(refreshError);
          }
        }

        return Promise.reject(error);
      }
    );
  };

  setupInterceptors(api);
  setupInterceptors(apiPayment);

  return {
    provide: {
      axios: () => api,
    },
  };
});

export { api, apiPayment };
