export enum ButtonType {
  primary = 'primary',
  secondary = 'secondary',
  white = 'white',
  gray = 'gray',
  blue = 'blue',
  green = 'green',
  transparent = 'transparent',
}

export enum ButtonSizeType {
  b_xl = 'b_xl',
  big = 'big',
  small = 'small',
  b_l = 'b_l',
  b_m = 'b_m',
  large = 'large',
  x_large = 'x_large',
  xxx_large = 'xxx_large',
}
