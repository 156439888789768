import {SessingStorageKey} from '~/constants';

export function getTranslateBySpanTag(string: string): string[] {
  return string?.split(/<\/?span>/);
}

export function getFileName(url: string) {
  var pathParts = url.split('/');
  var filenameWithExtension = pathParts[pathParts.length - 1];
  var filenameWithoutExtension = filenameWithExtension?.split('.')[0];
  return {filenameWithExtension, filenameWithoutExtension};
}

export const getMathDiscount = (price: number, discount: number) => {
  const resultPrice = price - price * (discount / 100);

  const factor = Math.pow(10, 2);
  let result = Math.floor(resultPrice * factor) / factor;
  if (result % 1 > 0.95) result = Math.round(result);
  return result;
};

export const goTo = (anchor: string) => {
  const el = document.querySelector(anchor);
  if (el) {
    // const headerOffset = 54;
    // const elementPosition = el.getBoundingClientRect().top;
    // const offsetPosition =
    //   elementPosition + window.pageYOffset - headerOffset;

    el.scrollIntoView({
      // top: offsetPosition,
      block: 'start',
      behavior: 'smooth',
    });
  }
};

export const getFileSize = (size: number) => {
  return (size / 1024 / 1024).toFixed(4);
};

export const setUtmTags = (params: any) => {
  let utmTagsString = '';
  if (params.query) {
    for (var key of Object.keys(params.query)) {
      if (
        key.includes('utm') ||
        key === 'adposition' ||
        key === 'matchtype' ||
        key === 'device' ||
        key === 'gad_source'
      ) {
        utmTagsString += `${key}=${params.query[key]};`;
      }
    }

    if (utmTagsString) {
      sessionStorage.setItem(SessingStorageKey.df_utm, utmTagsString);
    }
  }
};

export const getUtmTags = () => {
  return sessionStorage.getItem(SessingStorageKey.df_utm);
};
