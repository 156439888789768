import {getLocaleFromPath} from '~/helpers/getLocaleFromPath';
import {useCookie} from '#imports';
import {useUserStore} from '~/modules/User/user.store';
import {ListLangDFlat} from '~/constants';
import Logger from '~/helpers/logger';

export default defineNuxtRouteMiddleware((to, from) => {
  const config = useRuntimeConfig();
  const hasLocaleFromPath = getLocaleFromPath(to.fullPath);
  const langFromCookie = useCookie('lang');
  const dubaiRegex = /^\/?(ru|en|fr|es|ar)?\/dubai$/;
  const isDflat = config.public.vueAppBaseUrl.includes('d-flat.ru');
  const logger = Logger.getInstance();

  logger.info(`[ROUTING] from ${from.path} to ${to.path}`);

  if (
    hasLocaleFromPath &&
    isDflat &&
    !ListLangDFlat.find((lang) => lang.value === hasLocaleFromPath)
  ) {
    langFromCookie.value = config.public.vueAppLocale;
    return navigateTo(
      `/${config.public.vueAppLocale}` +
      to.fullPath.split(hasLocaleFromPath)[1],
    );
  }

  if (
    !hasLocaleFromPath &&
    !to.fullPath.includes('/dubai') &&
    !to.fullPath.includes('/launcherlogin') &&
    !to.fullPath.includes('/getshared')
  ) {
    const defaultLocale =
      langFromCookie.value ?? config.public.vueAppLocale;
    const currentLocale = defaultLocale;

    return navigateTo(`/${currentLocale}` + to.fullPath);
  }

  if (to.fullPath.includes('/pay') && !useCookie('df').value) {
    // if(to.query.code){
    //   useCookie('kompromo').value = 'komandor'
    // }
    const {showLoginModal} = useUserStore();
    showLoginModal();
    const locale = hasLocaleFromPath;
    // return navigateTo(`/${locale}` + '/plans')
    return navigateTo({
      path: `/${locale}/plans`,
      query: {...to.query},
    });
  }

  if (
    dubaiRegex.test(to.fullPath) ||
    to.fullPath.includes('/dubai2')
  ) {
    return navigateTo('/dubai/realty');
  }
});
