export default defineNuxtRouteMiddleware((to) => {
  const lowercasePath = to.path.toLowerCase();
  if (to.path !== lowercasePath) {
    return navigateTo(lowercasePath, {redirectCode: 301});
  }
  if (to.path !== '/' && to.fullPath.endsWith('/')) {
    const {path, query, hash} = to;
    const nextPath = path.replace(/\/+$/, '') || '/';
    const nextRoute = {path: nextPath, query, hash};
    return navigateTo(nextRoute, {redirectCode: 301});
  }
});
