import {defineStore} from 'pinia';
import YAML from 'yaml';
import type {LauncherInfo} from '~/interfaces';
import {api} from '~/plugins/axios';
import {launcherInfoSchema} from '~/schemes/launcher-info.schema';

export const useLauncherInfoStore = defineStore('launcherInfo', {
  state: () =>
    ({
      version: '',
      path: '',
      size: 0,
      sha512: '',
      releaseDate: new Date(),
      t: 0,
      isUpdating: false,
    } as LauncherInfo),
  actions: {
    async update() {
      if (
        !(this.t !== undefined && this.t + 60000 < +new Date()) ||
        this.isUpdating
      ) {
        return;
      }
      this.isUpdating = true;
      const runtimeConfig = useRuntimeConfig();

      const yamlFile = runtimeConfig.public.vueAppLauncherYaml;

      try {
        const response = await api.get(yamlFile);
        const launcherInfo = YAML.parse(response.data);
        const {error} = launcherInfoSchema.validate(launcherInfo);
        if (error) {
          throw new Error('Failed to get DF Launcher info');
        }
        // console.debug(launcherInfo);
        this.releaseDate = new Date(launcherInfo.releaseDate);
        this.version = launcherInfo.version;
        this.path = launcherInfo.files[0].url;
        this.size = launcherInfo.files[0].size;
        this.sha512 = launcherInfo.files[0].sha512;
      } catch (err) {
        const message = err.toString();
        if (message.includes('YAMLSyntaxError')) {
          console.error(`Could not parse ${yamlFile}`);
        } else {
          console.error(message, err.stack);
        }
      } finally {
        this.isUpdating = false;
        this.t = +new Date();
      }
    },
  },
});
