import {createI18n} from 'vue-i18n';
import axios from 'axios';
import localeConfig from '../i18n/config';
import localesMap from '../i18n/localesMap.json';
import Logger from '~/helpers/logger';

const logger = Logger.getInstance();

// Получаем локали с сервера или ставим дефолтную в случае ошибки
const getLocaleFromApi = async () => {
  // Объект для сравнения локалей
  const localesM = localesMap;
  try {
    const res = await axios.get(
      'https://dreamflat.design/locale/sheet/translate?locale=site',
    );
    const localesA = res.data;
    if (JSON.stringify(localesM) !== JSON.stringify(localesA)) {
      for (const key in localesA) {
        if (!Object.prototype.hasOwnProperty.call(localesM, 'key')) {
          localesM[key] = localesA[key];
        }
      }
      // Установка локалей из таблицы
      setLocaleMes(localesM);
      logger.info('[i18n] getLocaleFromApi success');
    }
  } catch (error) {
    // Установка локалей по дефолту
    setLocaleMes(localesMap);
    logger.error('[i18n] getLocaleFromApi bad', 'setLocales default');
    console.log(error);
  }
};
export const i18n = createI18n(localeConfig);

function setLocaleMes(localeMap: any) {
  const currentLocales: any = {
    en: {},
    es: {},
    ru: {},
    fr: {},
    ar: {},
  };
  for (const property in localeMap) {
    currentLocales.en[property] = localeMap[property].en;
    currentLocales.es[property] = localeMap[property].es;
    currentLocales.ru[property] = localeMap[property].ru;
    currentLocales.fr[property] = localeMap[property].fr;
    currentLocales.ar[property] =
      localeMap[property].ar || 'NO_VALUE';
  }
  i18n.global.setLocaleMessage('en', currentLocales.en);
  i18n.global.setLocaleMessage('es', currentLocales.es);
  i18n.global.setLocaleMessage('ru', currentLocales.ru);
  i18n.global.setLocaleMessage('fr', currentLocales.fr);
  i18n.global.setLocaleMessage('ar', currentLocales.ar);
}

export default defineNuxtPlugin(async ({vueApp}) => {
  // Дожидаемся, пока локали подгрузятся
  await getLocaleFromApi();
  // Рендерим с актуальными локалями
  vueApp.use(i18n);
});
