import axios from 'axios';

export interface IRealtySaves {
  dfdSave: string[];
  save: string[];
  meta: string[];
}

export interface IRealty {
  uuid: string;
  city: string;
  name: string;
  country: string;
  description: string;
  developer_site: string;
  gallery: string[];
  houses: {uuid: string}[];
  apartments: string[];
  preview: string;
  visible: boolean;
}

export interface IHouse {
  uuid: string;
  real_estate_uuid: string;
  visible: boolean;
  preview: string;
  name: string;
  street: string;
  apartments: string[];
  entrances: {uuid: string}[];
}
export interface IApartment {
  visible: boolean;
  uuid: string;
  floor_uuid: string | null;
  house_uuid: string | null;
  real_estate_uuid: string | null;
  name: string;
  preview: string;
  preview_modal: string;
  saves: IRealtySaves;
  gallery: Array<string>;
  properties: {
    square?: string;
    room_count?: string;
    description?: string;
  };
  apartment_type: {
    general?: {
      floor?: number;
      description?: string;
    };
    entrance?: {
      points?: string;
      modal?: {
        price?: string;
        street?: string;
        house?: number;
        section?: number;
        floor?: number;
        deadline?: string;
        developer_site?: string;
        hull?: string;
        properties?: {[key: string]: string};
      };
    };
  };
}

export interface IFloor {
  uuid: string;
  entrance_plan_uuid: string;
  name: string;
  plan: object;
  apartments: Array<IApartment>;
}

export interface IPlan {
  uuid: string;
  entrance_uuid: string;
  preview: string;
  languages: object;
  floors: Array<IFloor>;
}
export interface ISectionGenPlan {
  uuid: string;
  house_uuid: string;
  name: string;
  points: string;
  preview: string;
  plans: Array<IPlan>;
}
export interface SuccesResponse<T> {
  status: 'OK';
  data: T;
}
export interface ServerResponse<T> {
  error: any;
  status: any;
  payload: T;
}
export interface BadResponse {
  status: 'BAD';
  message: string;
}

export interface ServiceType {
  real_estate: string;
  apartment: string;
  plan: string;
  entrance: string;
  floor: string;
  house: string;
}

export type ServiceKeyType = keyof ServiceType;

export const getTypeUrl = () => {
  const currentUrl = location.href;
  const isDev =
    currentUrl.includes('localhost') ||
    currentUrl.includes('work.') ||
    currentUrl.includes('127.0.0.1');
  return isDev;
};

const baseUrl = 'real_estate/';

const paths = {
  get_all: baseUrl + 'all',
  get_by_id: baseUrl,
  nested: baseUrl + 'nested',
};

const service = {
  real_estate: 'real_estate',
  apartment: 'apartment',
  plan: 'plan',
  entrance: 'entrance',
  floor: 'floor',
  house: 'house',
  genplan: 'genplan',
};

export function getNested(
  service_name: ServiceKeyType,
  nested_by_name: ServiceKeyType,
  uuid: string,
): Promise<SuccesResponse<any[]> | BadResponse> {
  return new Promise((resolve) => {
    const apiBack = axios.create({
      baseURL: `https://${
        getTypeUrl() ? 'work.' : ''
      }dreamflat.design/${getTypeUrl() ? 'api' : 'proxy'}`,
    });
    apiBack
      .get(paths.nested, {
        params: {
          service: service[service_name],
          nested_by: service[nested_by_name],
          nested_id: uuid,
        },
      })
      .then((e) => {
        if (service_name === 'plan') {
          console.debug(e);
          console.debug(e.data.payload);
        }
        resolve({status: 'OK', data: e.data.payload});
      })
      .catch((e) => {
        console.debug(e);
        resolve({status: 'BAD', message: e.message});
      });
  });
}

export function getAllRealty(): Promise<
  SuccesResponse<IRealty[]> | BadResponse
> {
  return new Promise(
    (
      resolve: (data: SuccesResponse<IRealty[]>) => void,
      reject: (data: Error) => void,
    ) => {
      const apiBack = axios.create({
        baseURL: `https://${
          getTypeUrl() ? 'work.' : ''
        }dreamflat.design/${getTypeUrl() ? 'api' : 'proxy'}`,
      });
      apiBack
        .get<ServerResponse<IRealty[]>>(paths.get_all, {
          params: {
            service: service.real_estate,
            limit: 30,
            offset: 0,
          },
        })
        .then((e) => {
          resolve({status: 'OK', data: e.data.payload});
        })
        .catch((e) => {
          console.debug(e);
          reject(
            new Error(
              JSON.stringify({
                status: 'BAD',
                message: 'error request',
              }),
            ),
          );
        });
    },
  );
}
