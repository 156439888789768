<template>
  <div
    class="fullscreen bg-blue text-white text-center q-pa-md"
    style="text-align: center"
  >
    <div style="font-size: 30vh; text-align: center">
      {{ props.error!.statusCode }}
    </div>
    <div class="text-h2" style="opacity: 0.4; text-align: center">
      {{ $t('nothing') }}
    </div>
    <ui-button
      class="q-mt-xl error-btn"
      color="primary"
      to="/"
      :label="$t('goHome')"
      style="margin: 40px auto"
    />
  </div>
</template>

<script lang="ts" setup>
import {useI18n} from 'vue-i18n';
import UiButton from '~/components/Ui/UiButton.vue';

const props = defineProps({
  error: Object,
});

const {t} = useI18n();

console.log(props.error);
</script>
